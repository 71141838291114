import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'react-bootstrap'
import { TextInputForm } from '../components/Forms'
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { ClickButton } from '../components/ClickButton';
const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      if (username === "" || password === "") {
        throw new Error('Username Or password is Empty');
      }
      if (!username || !password) {
        throw new Error('Username and password are required');
      }


      const loginData = {
        username: username,
        password: password
      };

      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const responseData = await response.json();

    

      if (responseData.head.code === 400) {
        setError(responseData.msg);
      } else if (responseData.head.code === 200) {
        onLogin();
       navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login error:', error.message);
      setError(error.message);
    }
  };
  return (
    <div className='login-bg'>
      <Container fluid className='px-5 pad'>
        <Row className='justify-content-center'>
          <Col lg='3' md="6" xs='12' className='align-self-center p-0 m-0'>
            <div className='shadow login-box'>
              <div className='text-center'>
                <img src={require('../components/sidebar/images/logo.png')} className='img-fluid login-logo' alt='' />
              </div>
              <div className='text-center py-4 '>Login Your Credential</div>
              <div className='py-3'>
                <TextInputForm
                  placeholder={"User Name"}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className='py-3'>
                <TextInputForm
                  placeholder={"Password"}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  suffix_icon={showPassword ? <VscEye onClick={() => setShowPassword(false)} /> : <VscEyeClosed onClick={() => setShowPassword(true)} />}
                />
              </div>
              <div className='py-3 text-center'>
                <ClickButton label={<>Login</>} onClick={handleLogin}></ClickButton>
              </div>
              {error && (
                <Alert variant="danger">{error}</Alert> // Render error alert banner if error state is not null
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login