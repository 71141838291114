import React, { useState,useEffect } from 'react'
import { Container, Col, Row, Alert,Form ,Modal,Nav, NavItem, NavLink, TabContent, TabPane} from 'react-bootstrap'
import { TextInputForm, DropDownUI, Calender } from '../components/Forms'
import PageNav from '../components/PageNav';
import Preference from './Preference';
import { json, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { ClickButton } from '../components/ClickButton';
import Spinner from 'react-bootstrap/Spinner';
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OfflineCreation = () => {
  const navigate = useNavigate();
 
  const location = useLocation();
  const { type, rowData } = location.state || {};

  const [showPassword, setShowPassword] = useState(false);
  const stateOptions = [
    {
      value: "TN",
      label: "Tamil Nadu",
      country: 'india'
    },
    {
      value: "PY",
      label: "Pondycheri",
      country: 'india'
    },
    {
      value: "KL",
      label: "Kerala",
      country: 'india'
    }, {
      value: "TL",
      label: "Telugana",
      country: 'india'
    }
  ];
  const cityOptions = [
    {
      value: "Ariyalur",
      label: "Ariyalur",
      state: 'TN'
    },
    {
      value: "Chengalpattu",
      label: "Chengalpattu",
      state: 'TN'
    },
    {
      value: "Chennai",
      label: "Chennai",
      state: 'TN'
    },
    {
      value: "Coimbatore",
      label: "Coimbatore",
      state: 'TN'
    },
    {
      value: "Cuddalore",
      label: "Cuddalore",
      state: 'TN'
    },
    {
      value: "Dharmapuri",
      label: "Dharmapuri",
      state: 'TN'
    },
    {
      value: "Dindigul",
      label: "Dindigul",
      state: 'TN'
    },
    {
      value: "Erode",
      label: "Erode",
      state: 'TN'
    },
    {
      value: "Kallakurichi",
      label: "Kallakurichi",
      state: 'TN'
    },
    {
      value: "Kancheepuram",
      label: "Kancheepuram",
      state: 'TN'
    },
    {
      value: "Karur",
      label: "Karur",
      state: 'TN'
    },
    {
      value: "Krishnagiri",
      label: "Krishnagiri",
      state: 'TN'
    },
    {
      value: "Madurai",
      label: "Madurai",
      state: 'TN'
    },
    {
      value: "Nagapattinam",
      label: "Nagapattinam",
      state: 'TN'
    },
    {
      value: "Namakkal",
      label: "Namakkal",
      state: 'TN'
    },
    {
      value: "Nilgiris",
      label: "Nilgiris",
      state: 'TN'
    },
    {
      value: "Perambalur",
      label: "Perambalur",
      state: 'TN'
    },
    {
      value: "Pudukkottai",
      label: "Pudukkottai",
      state: 'TN'
    },
    {
      value: "Ramanathapuram",
      label: "Ramanathapuram",
      state: 'TN'
    },
    {
      value: "Ranipet",
      label: "Ranipet",
      state: 'TN'
    },
    {
      value: "Salem",
      label: "Salem",
      state: 'TN'
    },
    {
      value: "Sivagangai",
      label: "Sivagangai",
      state: 'TN'
    },
    {
      value: "Tenkasi",
      label: "Tenkasi",
      state: 'TN'
    },
    {
      value: "Thanjavur",
      label: "Thanjavur",
      state: 'TN'
    },
    {
      value: "Theni",
      label: "Theni",
      state: 'TN'
    },
    {
      value: "Tuticorin",
      label: "Tuticorin",
      state: 'TN'
    },
    {
      value: "Tiruchirappalli",
      label: "Tiruchirappalli",
      state: 'TN'
    },
    {
      value: "Tirunelveli",
      label: "Tirunelveli",
      state: 'TN'
    },
    {
      value: "Tirupathur",
      label: "Tirupathur",
      state: 'TN'
    },
    {
      value: "Tiruppur",
      label: "Tiruppur",
      state: 'TN'
    },
    {
      value: "Tiruvallur",
      label: "Tiruvallur",
      state: 'TN'
    },
    {
      value: "Tiruvannamalai",
      label: "Tiruvannamalai",
      state: 'TN'
    },
    {
      value: "Tiruvarur",
      label: "Tiruvarur",
      state: 'TN'
    },
    {
      value: "Vellore",
      label: "Vellore",
      state: 'TN'
    },
    {
      value: "Viluppuram",
      label: "Viluppuram",
      state: 'TN'
    },
    {
      value: "Virudhunagar",
      label: "Virudhunagar",
      state: 'TN'
    },
    {
      value: "Karaikal",
      label: "Karaikal",
      state: 'PY'
    },
    {
      value: "Mahe",
      label: "Mahe",
      state: 'PY'
    },
    {
      value: "Yanam",
      label: "Yanam",
      state: 'PY'
    },
    {
      value: "Alappuzha",
      label: "Alappuzha",
      state: 'KL'
    },
    {
      value: "Ernakulam",
      label: "Ernakulam",
      state: 'KL'
    },
    {
      value: "Idukki",
      label: "Idukki",
      state: 'KL'
    },
    {
      value: "Kottayam",
      label: "Kottayam",
      state: 'KL'
    },
    {
      value: "Kannur",
      label: "Kannur",
      state: 'KL'
    },
    {
      value: "Kasaragod",
      label: "Kasaragod",
      state: 'KL'
    },
    {
      value: "Thrissur",
      label: "Thrissur",
      state: 'KL'
    },
    {
      value: "Malappuram",
      label: "Malappuram",
      state: 'KL'
    },
    {
      value: "Kozhikode",
      label: "Kozhikode",
      state: 'KL'
    },
    {
      value: "Pathanamthitta",
      label: "Pathanamthitta",
      state: 'KL'
    },
    {
      value: "Thiruvananthapuram",
      label: "Thiruvananthapuram",
      state: 'KL'
    },
    {
      value: "Palakkad",
      label: "Palakkad",
      state: 'KL'
    },
    {
      value: "Kollam",
      label: "Kollam",
      state: 'KL'
    },
    {
      value: "Adilabad",
      label: "Adilabad",
      state: 'TL'
    },
    {
      value: "Bhadradri Kothagudem",
      label: "Bhadradri Kothagudem",
      state: 'TL'
    },
    {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    }, {
      value: "",
      label: "",
      state: 'TL'
    },
  ];
  const countryOptions = [
    {
      value: "India",
      label: "India"
    },
    {
      value: "usa",
      label: "USA"
    }
  ];
  const GenderList = [
    {
      value: "Male",
      label: "Male"
    },
    {
      value: "Female",
      label: "Female"
    }
  ];
  const MaterialList = [
    {
      value: "Any",
      label: "Any"
    },
    {
      value: "Single",
      label: "Single"
    },
    {
      value: "Waiting-Divorced",
      label: "Waiting-Divorced"
    },
    {
      value: "Divorced",
      label: "Divorced"
    },
    {
      value: "Widowed",
      label: "Widowed"
    },
    {
      value: "orphanage",
      label: "orphanage"
    }
  ];
  const LanguageList = [
    {
      value: "Tamil",
      label: "Tamil"
    },
    {
      value: "Telugu",
      label: "Telugu"
    },
    {
      value: "Kannada",
      label: "Kannada"
    },
    {
      value: "Garo",
      label: "Garo"
    },
    {
      value: "Hindi",
      label: "Hindi"
    },
    {
      value: "Malayalam",
      label: "Malayalam"
    },
    {
      value: "Marathi",
      label: "Marathi"
    }
  ];
  const EatingList = [
    {
      value: "Doesn't Matter",
      label: "Doesn't Matter"
    },
    {
      value: "Veg",
      label: "Veg"
    },
    {
      value: "Non Vegetarian",
      label: "Non Vegetarian"
    },
    {
      value: "Eggetarian",
      label: "Eggetarian"
    }

  ];

  const ReligiousList = [
    {
      value: "Hindu",
      label: "Hindu"
    },
    {
      value: "Christians",
      label: "Christians"
    },
    {
      value: "Muslims",
      label: "Muslims"
    }

  ];
 
  const optionList = [
    "Any Bachelors in Engineering / Computers",
    "Any Masters in Engineering / Computers",
    "Any Bachelors in Arts / Science / Commerce",
    "Any Masters in Arts / Science / Commerce",
    "Any Bachelors in Management",
    "Any Masters in Management",
    "Any Bachelors in Medicine in General / Dental / Surgeon",
    "Any Masters in Medicine - General / Dental / Surgeon",
    "Any Bachelors in Medicine Others",
    "Any Masters in Medicine Others",
    "Any Bachelors in Legal",
    "Any Masters in Legal",
    "Any Financial Qualification - ICWAI / CA / CS/ CFA",
    "Service - IAS / IPS / IRS / IES / IFS",
    "Doctorates",
    "Diploma",
    "Higher Secondary"
];

const EducationList = optionList.map(option => {
    // Split the option string by ' - ' to separate value and label
    const [value, label] = option.split(' - ');
    return {
        value: value,
        label: label || value
    };
});

const optionList1 = [
  "Any",
  "Private",
  "Government/PSU",
  "Business",
  "Defence",
  "Self Employed",
  "Not Working"
];

const EmploymentList = optionList1.map(option => ({
  value: option,
  label: option
}));
//.replace(/\s+/g, '').toLowerCase()


const occupationOptions = [
  "Administration",
  "Agriculture",
  "Airline",
  "Architecture & design",
  "Banking & finance",
  "Beauty & fashion",
  "Bpo & customer service",
  "Civil services",
  "Corporate professionals",
  "Defence",
  "Doctor",
  "Education & training",
  "Engineering",
  "Hospitality",
  "It & software",
  "Legal",
  "Media & entertainment",
  "Medical & healthcare-others",
  "Merchant navy",
  "Others",
  "Police / law enforcement",
  "Scientist",
  "Senior management"
];

const OccupationList = occupationOptions.map(option => ({
  value: option,
  label: option
}));

const casteOptions = [
  "Adi Dravidar",
  "Agamudayar / Arcot / Thuluva Vellala",
  "Arya Vysya",
  "Balija",
  "Boyar",
  "Brahacharanam",
  "Chakkiliyar",
  "Chettiar",
  "Devar / Thevar / Mukkulathor",
  "Gounder - Kongu Vellala Gounder",
  "Iyer",
  "Kallar",
  "Kammalar",
  "Kongu Vellala Gounder",
  "Maruthuvar",
  "Mudaliyar",
  "Nadar",
  "Naicker - Vanniya Kula Kshatriyar",
  "Padayachi",
  "Pallan / Devandra Kula Vellalan",
  "Pillai",
  "Reddy",
  "Sourashtra",
  "Sozhiya Vellalar",
  "Udayar",
  "Vannar",
  "Vannia Kula Kshatriyar",
  "Vellalar - Gounder",
  "Vellalar - Pillai",
  "Vellalar - Vellala",
  "Vettuva Gounder"
];

const CasteList = casteOptions.map(option => ({
  value: option,
  label: option
}));


const RasiList =[
  {
    value: "Aries",
    label: "மேஷம் (Mesham)"
  },
  {
    value: "Taurus",
    label: "ரிஷபம் (Rishabam)"
  },
  {
    value: "Gemini",
    label: "மிதுனம் (Mithunam)"
  },
  {
    value: "Cancer",
    label: "கடகம் (Kadagam)"
  },
  {
    value: "Leo",
    label: "சிம்மம் (Simham)"
  },
  {
    value: "Virgo",
    label: "கன்னி (Kanni)"
  },
  {
    value: "Libra",
    label: "துலாம் (Thulam)"
  },
  {
    value: "Scorpio",
    label: "விருச்சிகம் (Vrichigam)"
  },
  {
    value: "Sagittarius",
    label: "தனுசு (Dhanusu)"
  },
  {
    value: "Capricorn",
    label: "மகரம் (Makaram)"
  },
  {
    value: "Aquarius",
    label: "கும்பம் (Kumbam)"
  },
  {
    value: "Pisces",
    label: "மீனம் (Meenam)"
  }
];
 
const dosamlist =[
  {
    value : "yes",
    label : "yes"
  },
  {
    value : "no",
    label : "no"
  }
];
  const ProfileCreatedbyList = [
    {
      value: "Admin",
      label: "Admin"
    },
    {
      value: "myself",
      label: "myself"
    },
    {
      value: "Relative",
      label: "Relative"
    },
    {
      value: "Brother",
      label: "Brother"
    },
    {
      value: "Friend",
      label: "Friend"
    },
    {
      value: "Son",
      label: "Son"
    },
    {
      value: "Parents",
      label: "Parents"
    },
    {
      value: "Sister",
      label: "Sister"
    }


  ];
 

  const initialState = type === 'offffedit' ? { ...rowData } : {
    gender: "",
    first_name: "",
    phone_number: "",
    father_info : "",
    mother_info : "",
    height_feet: "",
    physical_status: "",
    marital_status: "",
    mother_tongue: "",
    habits: "",
    religious_status: "",
    religious : "",
    caste: "",
    star: "",
    rasi: "",
    dosam: "",
    country: "India",
    pincode: "",
    state: "",
    city: "",
    education: "",
    work_status: "",
    occupation: "",
    email: "",
    password: "",
    date_of_birth: "",
    profile_picture: "",
    profile_created_by: "",
    about_me  : "",
  };

  const [formData, setFormData] = useState(initialState);
  console.log(formData)
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otp, setOtp] = useState('');
  const [client_id, setclient_id] = useState('');
  const [customer_id,setcustomerid] =useState('');
  const [religindata, setreligindata] = useState([]);
  const [communitydata, setcommunitydata] = useState([]);
  const [castedata,setcastedata] =useState([]);
  console.log('sivadata',castedata);
  

  const fetchDatarelgion = async () => {
    try {
      setLoading(true)
      const response = await fetch('https://orchidsmatrimony.com/getallfiledata.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

        
      if (responseData.head.code === 200) {
        if (Array.isArray(responseData.body.religions)) {
          setreligindata(responseData.body.religions);
          console.log('siva123',responseData.body.religions)
        } else {
          throw new Error('Response data is not an array');
        }
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  const fetchDatacommunity = async (religious_status) => {
    try {
      setLoading(true)
      const response = await fetch('https://theheartmart.com/admin_panel/getjsoncommunites.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body : JSON.stringify({
          religion_id : religious_status,
        })
      });
      console.log('json',JSON.stringify({
        religion_id : religious_status,
      }))
      setLoading(false)
     

      const responseData = await response.json();

      console.log('maha',responseData)  
      if (responseData.head.code === 200) {
        if (Array.isArray(responseData.body.communities)) {
          setcommunitydata(responseData.body.communities);
          console.log('maha',responseData.body.communities)
        } else {
          throw new Error('Response data is not an array');
        }
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  const fetchDatacaste = async (community) => {
    try {
      setLoading(true)
      const response = await fetch('https://theheartmart.com/admin_panel/getjsoncaste.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body : JSON.stringify({
          community_id : community,
        })
      });
      
      setLoading(false)
     

      const responseData = await response.json();

      console.log('maha',responseData)  
      if (responseData.head.code === 200) {
        if (Array.isArray(responseData.body.caste)) {
          setcastedata(responseData.body.caste);
          console.log('maha',responseData.body.caste)
        } else {
          throw new Error('Response data is not an array');
        }
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value
    });
  };


  const handleSubmit = async () => {
    for (const key in formData) {
      if (formData[key] === '') {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; // Exit the function early if any field is empty
      }
    }
    try {
      setLoading(true)

      
      
        const response = await fetch('https://admin.orchidsmatrimony.com/api/src/offline_register.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        const responseData = await response.json();

      

        if (responseData.head.code === 200) {
          toggleTab('idVerification');
          const id = responseData.head.body.customer_id;
          setcustomerid(id);
          toast.success('Offline customer data insert successfully!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
  
          // Navigate to the user list page after a delay
          setTimeout(() => {
          //  navigate("/console/offlineregister");
          }, 2000);
         // navigate("/console/offlineregister");
          setLoading(false);
        } else {
          setError(responseData.head.msg);
        }
      
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdate = async () => {
    try {
      const updatedFormData = {
        edit_user_id: rowData.customer_id,
        gender : formData.gender,
        first_name : formData.first_name,
        phone_number : formData.phone_number,
        email : formData.email,
        address : formData.address,
        father_info : formData.father_info,
        mother_info : formData.mother_info,
        height_feet : formData.height_feet,
        physical_status : formData.physical_status,
        marital_status : formData.marital_status,
        mother_tongue : formData.mother_tongue,
        habits : formData.habits,
        religious_status : formData.religious,
        caste :formData.caste,
        star : formData.star,
        dosam : formData.dosam,
        rasi : formData.rasi,
        country :"India",
        state : formData.state,
        city : formData.city,
        pincode : formData.pincode,
        education : formData.education,
        work_status : formData.work_status,
        occupation :formData.occupation,
        password : formData.password,
        date_of_birth : formData.date_of_birth,
        profile_picture: formData.profile_picture,
        profile_created_by: formData.profile_created_by,
        about_me  : formData.about_me,
      };
      var bodydata = JSON.stringify(updatedFormData)
  
      setFormData(updatedFormData);

   
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/offline_register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: bodydata
      });



      const responseData = await response.json();


      if (responseData.head.code === 200) {
        toast.success('Offline customer data updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Navigate to the user list page after a delay
        setTimeout(() => {
          navigate("/console/offlineregister");
        }, 2000);
        
      } else {
        console.log('Error:', responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
 
  const handleVerifyClick = async () => {
    const aadhar = formData.aadhar;
    if (!/^\d{12}$/.test(aadhar)) {
      toast.error('Aadhar number must be a 12-digit number!', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return; // Exit the function early if mobile number is invalid
    }
  
      try {
        const response = await fetch('https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp', {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json',
            'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxMDgzMjQ2NCwianRpIjoiN2ZmZWI2M2YtM2Y5My00OGU2LWJiZWMtNjE0Y2M0ZDNiZjBjIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2Lm9yY2hpZHNtYXRyaW1vbnlAc3VyZXBhc3MuaW8iLCJuYmYiOjE3MTA4MzI0NjQsImV4cCI6MjAyNjE5MjQ2NCwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInVzZXIiXX19.b-isHf5Jdt20IEdwukTZ6su2Mio2V7GNjeTi6lOJjVs'
          },
          body: JSON.stringify({
            id_number: formData.aadhar,
          }),
        });
        const data = await response.json();

  
        
        if (response.ok) {
          setShowModal(true);
          const clint = data.data.client_id;
          setclient_id(clint);
        } else {
          setError('Failed to generate OTP. Please try again.');
        }
      } catch (error) {
        setError('An error occurred while verifying Aadhar.');
      }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const verifyAadhar = async (otp, client_id) => {
    try {
      const response = await fetch('https://kyc-api.surepass.io/api/v1/aadhaar-v2/submit-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxMDgzMjQ2NCwianRpIjoiN2ZmZWI2M2YtM2Y5My00OGU2LWJiZWMtNjE0Y2M0ZDNiZjBjIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2Lm9yY2hpZHNtYXRyaW1vbnlAc3VyZXBhc3MuaW8iLCJuYmYiOjE3MTA4MzI0NjQsImV4cCI6MjAyNjE5MjQ2NCwidXNlcl9jbGFpbXMiOnsic2NvcGVzIjpbInVzZXIiXX19.b-isHf5Jdt20IEdwukTZ6su2Mio2V7GNjeTi6lOJjVs', // Replace with your access token
        },
        body: JSON.stringify({
          client_id: client_id,
          otp: otp,
        }),
      });
  
      const data = await response.json();
  
      return data; // Return the response data
    } catch (error) {
      console.error('Error:', error);
      return { error: 'An error occurred while submitting OTP.' }; // Return an error object
    }
  };

  const saveaadhardata = async (cus_id,response1) =>{
    try {
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/aadhar_save.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customer_id :cus_id,
          data : response1, }),
      });
      const data = await response.json();

      if(response.ok){
        toast.success('aadhar verfied successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Navigate to the user list page after a delay
        setTimeout(() => {
          navigate("/console/offlineregister");
        }, 2000);
      }else{

      }
      
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }

  }

  const handleOtpSubmit = async () => {
    try {
      const response1 = await verifyAadhar(otp, client_id);
    // Replace client_id with your actual client ID
      const cus_id = customer_id;
      saveaadhardata(cus_id,response1);
    } catch (error) {
      setError('An error occurred while submitting OTP.');
    }
    setShowModal(false);
  };

  const handleChangeOtp = (e) => {
    setOtp(e.target.value);
  };
  const [activeTab, setActiveTab] = useState('customerCreation');

  const toggleTab = tab => {
   
    if (activeTab !== tab) {
     
      setActiveTab(tab);
    }
  };
  
  
  const [selectedImage, setSelectedImage] = useState(null);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Image = reader.result; 
        setSelectedImage(reader.result);// Get base64 representation of the selected image
        setFormData({
          ...formData,
          profile_picture: base64Image // Update profile_picture field with base64 image data
        });
      };
      reader.readAsDataURL(file); // Read file as data URL
    }
  };
  const setDateOfBirth = (date) => {
    setFormData(prevData => ({
      ...prevData,
      date_of_birth : date,
  }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value // Update the specified field in formData with the provided value
    });
  };

  const [townData, setTownData] = useState([]);

  const handlePincode = async () => {
    try {
      const pincode = formData.pincode; 
      const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
      const data = await response.json();
  
   
  
      if (data.error) {
        setTownData([]);
      } else {
        const city = data[0].PostOffice[0].Block; // Extract city from PostOffice
        const state = data[0].PostOffice[0].State;
        const pincode= data[0].PostOffice[0].Pincode; // Extract state from PostOffice

      setTownData(data);

      // Update formData with city and state
      setFormData({
        ...formData,
        city,
        state,
        pincode
      });

      }
    } catch (error) {
      console.error('Error:', error);
      setTownData([]);
    }
  };

  
  

  const starOptions = {
    Aries: ["அஸ்வினி (Ashwini)", "பரணி (Bharani)", "கார்த்திகை (Krittika)"],
    Taurus: ["கார்த்திகை (Krittika)", "ரோகிணி (Rohini)", "மிருகசிரம் (Mrigashira)"],
    Gemini: ["மிருகசிரம் (Mrigashira)", "ஆர்த்தா (Ardra)", "புனர்வஸு (Punarvasu)"],
    Cancer: ["புனர்வஸு (Punarvasu)", "பூசம் (Pushya)", "ஆயில்யம் (Ashlesha)"],
    Leo: ["மகம் (Magha)", "பூராடம் (Purva Phalguni)", "உத்திரட்டாதி (Uttara Phalguni)"],
    Virgo: ["உத்திரட்டாதி (Uttara Phalguni)", "ஹஸ்தம் (Hasta)", "சித்ரா (Chitra)"],
    Libra: ["சித்ரா (Chitra)", "ஸ்வாதி (Swati)", "விசாகம் (Vishakha)"],
    Scorpio: ["விசாகம் (Vishakha)", "அனுஷம் (Anuradha)", "ஜேஷ்டா (Jyeshtha)"],
    Sagittarius: ["மூலம் (Mula)", "பூர்வாஷாடம் (Purva Ashadha)", "உத்திராஷாடம் (Uttara Ashadha)"],
    Capricorn: ["உத்திராஷாடம் (Uttara Ashadha)", "திருவோணம் (Shravana)", "தனிஷ்டா (Dhanishta)"],
    Aquarius: ["தனிஷ்டா (Dhanishta)", "ஷதபிஷம் (Shatabhisha)", "பூர்வப்பிரப்தம் (Purva Bhadrapada)"],
    Pisces: ["பூர்வப்பிரப்தம் (Purva Bhadrapada)", "உத்திரப்பிரப்தம் (Uttara Bhadrapada)", "ரேவதி (Revati)"]
  };

  const buildCategorizedOptions = (rasi) => {
    if (starOptions[rasi]) {
      return starOptions[rasi].map((star, index) => ({
        value: star,
        label: star,
      }));
    }
    return [];
  };
  const optionsstar = buildCategorizedOptions(formData.rasi);
useEffect(() => {
  fetchDatarelgion();
  if(formData.religious_status!== ''){
    if(formData.religious === ''){
   let filteredUserData = religindata.filter(item => item.id === formData.religious_status);
  console.log('Filtered userdata:', filteredUserData);
  if (filteredUserData.length > 0) {
    setFormData({ ...formData, religious: filteredUserData[0].name });
  }
}
}
}, [formData])
const optionlistdata = religindata.map(religion => ({
  value: religion.id,
  label: religion.name
}));
const optionlistdata1 = communitydata.map(community => ({
  value: community.id,
  label: community.name
  }));
  const optionlistdata2 = castedata.map(caste => ({
    value: caste.name,
    label: caste.name
    }));
  return (
    
    <div className='regular main-content'>
      {loading ? <center><Spinner animation="border" variant="dark" /> </center> :
        <Container>

          <Row>
            
            <Col lg='12' className='py-3'>
              <PageNav pagetitle={type === "online" ? "Online View" : `Offline ${type === 'view' ? 'View' : type === "offline" ? "view" : type === 'offffedit' ? 'Update' : 'Creation'}`}></PageNav>
            </Col>
            <Col lg='12'>
            <Nav tabs >
              <NavItem>
                <NavLink
                  className={`create-btn  mx-2 activeTab === 'customerCreation' ? 'active' : '' `}
                  onClick={() => { toggleTab('customerCreation'); }}
                >
                  Customer Creation
                </NavLink>
              </NavItem>
              {type !== 'offline' && type !== 'offffedit' && (
                <NavItem>
                  <NavLink
                    className={ `create-btn mx-2 activeTab === 'idVerification' ? 'active' : '' `}
                    onClick={() => { toggleTab('idVerification'); }}
                  >
                    ID Verification
                  </NavLink>
                </NavItem>
              )}
            </Nav>
              {activeTab === "idVerification" && (
             <>
              
                <Col lg='12'>
                  <div className='py-3'>Id Verification</div>
                </Col>

                {/* Add this button inside the appropriate column */}
                <Col lg='6' className='py-3'>
                  <div className='aadhaar w-100'>
                    <div className=' w-100'>
                      <TextInputForm 
                        value={formData.aadhar}
                        onChange={(e) => handleChange(e, 'aadhar')}
                        labelname={<>Aadhaar No.</>} />
                    </div>
                    <div className='mx-3'>
                      <ClickButton variant="primary" onClick={handleVerifyClick} label={<>Verify</>}>Verify</ClickButton>
                    </div>
                  </div>
                </Col>
             
          

            {/* Modal for OTP */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Verify Aadhar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleOtpSubmit}>
                  <Form.Group controlId="formOtp">
                    <Form.Label>Enter OTP received on Aadhar</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleChangeOtp}
                    />
                  </Form.Group>
                  {error && <Alert variant='danger'>{error}</Alert>}
                  <Col lg='6' className='py-3'>
                    <ClickButton variant="primary" type="submit" onClick={handleOtpSubmit} label={<>submit</>}>Submit OTP</ClickButton>
                  </Col>
                </Form>
              </Modal.Body>
            </Modal>
          </>
        )}
        {activeTab === "customerCreation" && (
          <>
      <Container>

      <Row>
      <Col lg='12' className='py-3'>
        {selectedImage && (
                <div className="image-preview py-3 ms-auto">
                  <img src={selectedImage} alt="Selected" className="img-fluid" />
                </div>
            )}
        </Col>
            <Col lg='6' className='py-3 align-self-center'> <div> Basic Details</div> </Col>
            <Col lg='6' className='py-3 align-self-center'>
           
              <div class="file-upload text-end">
                <input type="file" id="upload" accept=".jpg, .jpeg, .png"  onChange={handleImageChange} />
                <label for="upload" class="my-2 upload text-white">Upload Your Image</label>
              </div>
             
            </Col>
            <Col lg="3" md='3' xs='12' className='py-3'>
            {type === "offffedit" ? (
              <DropDownUI 
              optionlist={ProfileCreatedbyList}
              name="profile_created_by"
              value={formData.profile_created_by}
              placeholder="Select"
                labelname="Profile Created "
                onChange={(updatedFormData) => setFormData({ ...formData, profile_created_by : updatedFormData.profile_created_by })}
                ></DropDownUI>
                ) : (
                <DropDownUI 
                optionlist={ProfileCreatedbyList}
                name="profile_created_by"
                value={type === "offline" ? rowData.profile_created_by : formData.profile_created_by}
                placeholder="Select"
                labelname="Profile Created "
                onChange={(updatedFormData) => setFormData({ ...formData, profile_created_by : updatedFormData.profile_created_by })}
                ></DropDownUI>
                )}
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <TextInputForm
                  placeholder={'Name'}
                  labelname={'Name'}
                  name='first_name'
                  value={formData.first_name}
                  onChange={(e) => handleChange(e, 'first_name')}
                />
              ) : (
                <TextInputForm
                  placeholder={'Name'}
                  labelname={'Name'}
                  name='first_name'
                  value={type === "offline" ? rowData.first_name : formData.first_name}
                  onChange={(e) => handleChange(e, 'first_name')}
                  disabled={type === "offline"}
                />
              )}
            </Col>
            <Col lg="3" md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <DropDownUI
                  optionlist={GenderList}
                  name="gender"
                  placeholder="Select"
                  labelname="Gender Select"
                  value={formData.gender}
                  onChange={(updatedFormData) => setFormData({ ...formData, gender: updatedFormData.gender })}>
                </DropDownUI>
              ) : (
                <DropDownUI
                  optionlist={GenderList}
                  name="gender"
                  placeholder="Select"
                  labelname="Gender Select"
                  value={type === "offline" ? rowData.gender : formData.gender}
                  onChange={(updatedFormData) =>setFormData({ ...formData, gender: updatedFormData.gender })}>
                </DropDownUI>
              )}

            </Col>

            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                <Calender setLabel={(date) => setLabel(date, 'date_of_birth')}  selectedDate={formData.date_of_birth} onSelectDate={setDateOfBirth} calenderlabel="Date of Birth" />
              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <TextInputForm placeholder={"Height"}
                  labelname="Height Select"
                  name="height_feet"
                  value={formData.height_feet}
                  onChange={(e) => handleChange(e, 'height_feet')} />
              ) : (
                <TextInputForm placeholder={"Height"}
                  labelname="Height Select"
                  name="height_feet"
                  value={type === 'offline' ? rowData.height_feet : formData.height_feet}
                  onChange={(e) => handleChange(e, 'height_feet')} />
              )}
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <TextInputForm placeholder={"Enter Mob No."}
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={(e) => handleChange(e, 'phone_number')}
                  labelname={"Enter Mobile No."} />
              ) : (
                <TextInputForm placeholder={"Enter Mob No."}
                  name="phone_number"
                  value={type === 'offline' ? rowData.phone_number : formData.phone_number}
                  onChange={(e) => handleChange(e, 'phone_number')}
                  labelname={"Enter Mobile No."} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <TextInputForm placeholder={"Father Info"}
                  name="father_info"
                  value={formData.father_info}
                  onChange={(e) => handleChange(e, 'father_info')}
                  labelname={"Father Info"} />
              ) : (
                <TextInputForm placeholder={"Father Info"}
                  name="father_info"
                  value={type === "offline" ? rowData.father_info : formData.father_info}
                  onChange={(e) => handleChange(e, 'father_info')}
                  labelname={"Father Info"} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <TextInputForm
                 placeholder={"Mother Info"}
                  name="mother_info"
                  value={formData.mother_info}
                  onChange={(e) => handleChange(e, 'mother_info')}
                  labelname={"Mother Info"} />
              ) : (
                <TextInputForm
                 placeholder={"Mother Info"}
                  name="mother_info"
                  value={type === 'offline' ? rowData.mother_info : formData.mother_info}
                  onChange={(e) => handleChange(e, 'mother_info')}
                  labelname={"Mother Info"} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <TextInputForm placeholder={"Enter the email"}
                  type={"email"}
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleChange(e, 'email')}
                  labelname={"Enter the email"} />
              ) : (
                <TextInputForm placeholder={"Enter the email"}
                  type={"email"}
                  name="email"
                  value={type === "offline" ? rowData.email : formData.email}
                  onChange={(e) => handleChange(e, 'email')}
                  labelname={"Enter the email"} />
              )}
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === 'offffdit' ? (
                <TextInputForm placeholder={"Physical Status"}
                  name="physical_status"
                  value={formData.physical_status}
                  onChange={(e) => handleChange(e, 'physical_status')}
                  labelname={"Physical status"} />
              ) : (
                <TextInputForm placeholder={"Physical Status"}
                  name="physical_status"
                  value={type === 'offline' ? rowData.physical_status : formData.physical_status}
                  onChange={(e) => handleChange(e, 'physical_status')}
                  labelname={"Physical status"} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === 'offffedit' ? (
                  <DropDownUI optionlist={MaterialList}
                    placeholder={"Marital Status"}
                    name="marital_status"
                    value={formData.marital_status}
                    labelname={"Marital Status"}
                    onChange={(updatedFormData) => setFormData({ ...formData, marital_status: updatedFormData.marital_status })} />
                ) : (
                  <DropDownUI optionlist={MaterialList}
                    placeholder={"Marital Status"}
                    name="marital_status"
                    value={type === 'offline' ? rowData.marital_status : formData.marital_status}
                    labelname={"Marital Status"}
                    onChange={(updatedFormData) => setFormData({ ...formData, marital_status: updatedFormData.marital_status })} />
                )}

              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <DropDownUI optionlist={LanguageList}
                  placeholder={"Mother Tounge"}
                  name="mother_tongue"
                  value={formData.mother_tongue}
                  labelname={"Mother Tounge"}
                  onChange={(updatedFormData) => setFormData({ ...formData, mother_tongue: updatedFormData.mother_tongue })} />
              ) : (
                <DropDownUI optionlist={LanguageList}
                  placeholder={"Mother Tounge"}
                  name="mother_tongue"
                  value={type === 'offline' ? rowData.mother_tongue : formData.mother_tongue}
                  labelname={"Mother Tounge"}
                  onChange={(updatedFormData) => setFormData({ ...formData, mother_tongue: updatedFormData.mother_tongue })} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <DropDownUI optionlist={EatingList}
                  placeholder={"Eating Habits"}
                  name="habits"
                  value={formData.habits}
                  labelname={"Eating habits"}
                  onChange={(updatedFormData) => setFormData({ ...formData, habits: updatedFormData.habits })} />
              ) : (
                <DropDownUI optionlist={EatingList}
                  placeholder={"Eating Habits"}
                  name="habits"
                  value={type === "offline" ? rowData.habits : formData.habits}
                  labelname={" habits"}
                  onChange={(updatedFormData) => setFormData({ ...formData, habits: updatedFormData.habits })} />
              )}
              
            </Col>
            <Col lg="9"></Col>
            
            <Col lg='12' md='12' xs='12' className='py-3'>
              <div>Religious Details</div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <DropDownUI 
                optionlist={optionlistdata}
                  
                  placeholder={"Enter Your Religious"}
                  name="religious_status"
                  value={formData.religious_status}
                  labelname={"Enter Your Religious"}
                  onChange={(updatedFormData) =>{
                    setFormData({ ...formData, religious_status: updatedFormData.religious_status })
                    fetchDatacommunity(updatedFormData.religious_status);
                    
                  }
                } />
              ) : (
                <DropDownUI 
                optionlist={optionlistdata}
                 
                  placeholder={"Enter Your Religious"}
                  name="religious_status"
                  value={type === "offline" ? rowData.religious_status : formData.religious_status}
                  labelname={"Enter Your Religious"}
                  onChange={(updatedFormData) => {
                    setFormData({ ...formData, religious_status: updatedFormData.religious_status })
                    fetchDatacommunity(updatedFormData.religious_status);
                   
                   
                  }} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <DropDownUI 
                optionlist={optionlistdata1}
                  
                  placeholder={"Enter Your community"}
                  name="community"
                  value={formData.community}
                  labelname={"Enter Your community"}
                  onChange={(updatedFormData) =>{
                    setFormData({ ...formData, community: updatedFormData.community })
                    fetchDatacaste(updatedFormData.community);
                  }
                } />
              ) : (
                <DropDownUI 
                optionlist={optionlistdata1}
                 
                  placeholder={"Enter Your community"}
                  name="community"
                  value={type === "offline" ? rowData.community : formData.community}
                  labelname={"Enter Your community"}
                  onChange={(updatedFormData) => {
                    setFormData({ ...formData, community: updatedFormData.community })
                    fetchDatacaste(updatedFormData.community);
                  }} />
              )}


            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              {type === "offffedit" ? (
                <DropDownUI optionlist={optionlistdata2}
                  placeholder={"caste"} name="caste"
                  labelname={"caste"}
                  value={formData.caste}
                  onChange={(updatedFormData) => setFormData({ ...formData, caste: updatedFormData.caste })} />
              ) : (
                <DropDownUI optionlist={optionlistdata2}
                  placeholder={"caste"} name="caste"
                  labelname={"caste"}
                  value={type === "offline" ? rowData.caste : formData.caste}
                  onChange={(updatedFormData) => setFormData({ ...formData, caste: updatedFormData.caste })} />
              )}

            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
            {type === "offffedit" ? (
                <DropDownUI 
                optionlist={RasiList}
                  placeholder={"Raasi"} name="rasi"
                  labelname={"Raasi"}
                  value={formData.rasi}
                  onChange={(updatedFormData) => setFormData({ ...formData, rasi: updatedFormData.rasi })} />
              ) : (
                <DropDownUI optionlist={RasiList}
                  placeholder={"Raasi"} name="rasi"
                  labelname={"Raasi"}
                  value={type === "offline" ? rowData.rasi : formData.rasi}
                  onChange={(updatedFormData) => setFormData({ ...formData, rasi: updatedFormData.rasi })} />
              )}
             

            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
              {type === "offffedit" ? (
                <DropDownUI optionlist={optionsstar}
                  placeholder={"Star"} name="star"
                  labelname={"Star"}

                  value={formData.star}
                  onChange={(updatedFormData) => setFormData({ ...formData, star: updatedFormData.star })} />
              ) : (
             
              <DropDownUI
                optionlist={optionsstar}
                placeholder="Select your Star"
                name="star"
                labelname="Star"
                value={type === "offline" ? rowData.star : formData.star}
                onChange={(updatedFormData) => setFormData({ ...formData, star: updatedFormData.star })}
              />
              )}
              </div>
            </Col>
           
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === "offffedit" ? (
                  <DropDownUI 
                    optionlist ={dosamlist}
                    placeholder={"Dhosam"}
                    name="dosam"
                    onChange={(updatedFormData) => setFormData({ ...formData, dosam: updatedFormData.dosam })}
                    
                    value={formData.dosam}
                    labelname={"Dhosam"} />
                ) : (
                  <DropDownUI 
                  optionlist ={dosamlist}
                  placeholder={"Dhosam"}
                    name="dosam"
                    onChange={(updatedFormData) => setFormData({ ...formData, dosam: updatedFormData.dosam })}
                    value={type === 'offline' ? rowData.dosam : formData.dosam}
                    labelname={"Dhosam"} />
                )}

              </div>
            </Col>
            <Col lg='12' md='12' xs='12' className='py-3'>
              <div>Location Details</div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === "offffedit" ? (
                  <TextInputForm placeholder={"Address"}
                    name="address"
                    value={formData.address}
                    onChange={(e) => handleChange(e, 'address')}
                    labelname={"Address"} />
                ) : (
                  <TextInputForm placeholder={"Address"}
                    name="address"
                    value={type === "offline" ? rowData.address : formData.address}
                    onChange={(e) => handleChange(e, 'address')}
                    labelname={"Address"} />
                )}

              </div>
            </Col>
           
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === "offffedit" ? (
                  <TextInputForm placeholder={"Pincode"}
                    name="pincode"
                    onChange={(e) => {handleChange(e, 'pincode');
                    handlePincode();}}
                    value={formData.pincode}
                    labelname={"pincode"} 
                    onBlur={handlePincode}/>
                ) : (
                  <TextInputForm placeholder={"Pincode"}
                    name="pincode"
                    onChange={(e) => {handleChange(e, 'pincode');
                    handlePincode();}}
                    value={type === "offline" ? rowData.pincode : formData.pincode}
                    labelname={"pincode"}
                    onBlur={handlePincode} />
                )}

              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
              {type === "offffedit" ? (
                  <TextInputForm
                    value={formData.state}
                    onChange={(e) => handleChange(e, 'state')}
                    labelname={"state"}
                    name="state"
                    placeholder="Select State"
                  />
                  ) : (
                    <TextInputForm
                    
                    onChange={(e) => handleChange(e, 'state')}
                    value={type === "offline" ? rowData.state : formData.state}
                    name="state"
                    placeholder="Select State"
                    labelname={"state"}
                  />
                    )}
              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
              {type === "offffedit" ? (
                  <TextInputForm
                    value={formData.city}
                    onChange={(e) => handleChange(e, 'city')}
                    placeholder={"City"}
                    name="city"
                    labelname={"City"}
                  />
                  ) : (
                    <TextInputForm
                   
                    onChange={(e) => handleChange(e, 'city')}
                    value={type === "offline" ? rowData.city : formData.city}
                    name="city"
                    placeholder="Select City"
                    labelname={"City"}
                  />
                    )}
              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
              {type === "offffedit" ? (
                <DropDownUI
                  optionlist={countryOptions}
                  placeholder={"Select Country"}
                  name="country"
                  labelname={"country"}
                  value={'India'}
                  onChange={(updatedFormData) => setFormData({ ...formData, country: updatedFormData.country })}
                />
                ) : (
                  <DropDownUI
                  optionlist={countryOptions}
                  placeholder={"Select Country"}
                  name="country"
                  labelname={"country"}
                  value={'India'}
                 
                  onChange={(updatedFormData) => setFormData({ ...formData, country: updatedFormData.country })}
                />
                )}
              </div>
            </Col>
            
           
            
            <Col lg='12' md='12' xs='12' className='py-3'>
              <div>Professional Details</div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === "offffedit" ? (
                  <DropDownUI optionlist={EducationList}
                    name="education"
                    value={formData.education}
                 
                    onChange={(updatedFormData) => setFormData({ ...formData, education: updatedFormData.education })}
                    placeholder={"Education"}
                    labelname={"Education"} />
                ) : (
                  <DropDownUI optionlist={EducationList}
                    name="education"
                    value={type === "offline" ? rowData.education : formData.education}
                    
                    onChange={(updatedFormData) => setFormData({ ...formData, education: updatedFormData.education })}
                    placeholder={"Education"}
                    labelname={"Education"} />
                )}

              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === "offffedit" ? (
                  <DropDownUI
                  optionlist={EmploymentList}
                   placeholder={"Employed In"}
                    name="work_status"
                    value={formData.work_status}
      
                    onChange={(updatedFormData) => setFormData({ ...formData, work_status: updatedFormData.work_status })}
                    labelname={"Employed In"} />
                ) : (
                  <DropDownUI 
                  optionlist={EmploymentList}
                  placeholder={"Employed In"}
                    name="work_status"
                    value={type === "offline" ? rowData.work_status : formData.work_status}
                    onChange={(updatedFormData) => setFormData({ ...formData, work_status: updatedFormData.work_status })}
                    labelname={"Employed In"} />
                )}

              </div>
            </Col>
            <Col lg='3' md='3' xs='12' className='py-3'>
              <div>
                {type === "offfedit" ? (
                  <DropDownUI
                  optionlist={OccupationList}
                   placeholder={"Occupation"}
                    name="occupation"
                    value={formData.occupation}
                    
                    onChange={(updatedFormData) => setFormData({ ...formData, occupation: updatedFormData.occupation })}
                    labelname={"Occupation"} />
                ) : (
                  <DropDownUI
                  optionlist={OccupationList}
                   placeholder={"Occupation"}
                    name="occupation"
                    value={type === "offline" ? rowData.occupation : formData.occupation}
                    onChange={(updatedFormData) => setFormData({ ...formData, occupation: updatedFormData.occupation })}
                    labelname={"Occupation"} />
                )}

              </div>
            </Col>


            <Col lg='12' className='py-3'> <div> About Yourself</div></Col>
            <Col lg='6' className='py-3'>
              {type === "offffedit" ? (
                <div className='w-100'>
                  <textarea className='form-cntrl w-100'
                    value={formData.about_me}
                    onChange={(e) => handleChange(e, 'about_me')}
                    name="about_me"
                    placeholder='About'
                    labelname={"About"} />
                </div>
              ) : (
                <div className='w-100'>
                  <textarea className='form-cntrl w-100'
                    value={type === "offline" ? rowData.about_me : formData.about_me}
                    onChange={(e) => handleChange(e, 'about_me')}
                    name="about_me"
                    placeholder='About'
                    labelname={"About"} />
                </div>
              )}

            </Col>
            {type === 'offffedit' ? null : (
             <>
            <Col lg='12' className='py-3'> <div> Email Address</div></Col>
            <Col lg='6' md='6' xs='12' className='py-2'>
              {type === "offffedit" ? (
                <TextInputForm
                  placeholder={'Email'}
                  labelname={'Email'}
                  name='email'
                  value={formData.email}
                  onChange={(e) => handleChange(e, 'email')}
                />
              ) : (
                <TextInputForm
                  placeholder={'Email'}
                  labelname={'Email'}
                  name='email'
                  value={type === "offline" ? rowData.email : formData.email}
                  onChange={(e) => handleChange(e, 'email')}
                />
                )}

            </Col>
            
           
            <Col lg='6' md='6' xs='12' className='py-2'>
              {type === "Offline" ? null : (
                <TextInputForm
                  placeholder={'Password'}
                  suffix_icon={showPassword ? <VscEye onClick={() => setShowPassword(false)} /> : <VscEyeClosed onClick={() => setShowPassword(true)} />}
                  labelname={'Password'}
                  type={showPassword ? 'text' : 'password'}
                  name='password'
                  value={type === "offline" ? rowData.password : formData.password}
                  onChange={(e) => handleChange(e, 'password')}
                />
              )}

            </Col>
            </>
            )}
            <Col lg='12' md='12' xs='12'>
          <div className='page-nav text-center py-3'>
          {type === 'offline' ? (
            <>
              <span className='mx-2'><ClickButton label={'back'} onClick={() => navigate("/console/offlineregister")} /></span>
            </>
          ) : (
            <>
              {type === 'offffedit' ? (

                 <>
                  <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                <span className='mx-2'><ClickButton label={'Update'} onClick={handleUpdate} /></span>
                <span className='mx-2'><ClickButton label={'Cancel'} onClick={() => navigate("/console/offlineregister")} /></span>
                </>
              ) : (
                <>
                 <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                  <span className='mx-2'><ClickButton label={'Submit'} onClick={handleSubmit} /></span>
                  <span className='mx-2'><ClickButton label={'Cancel'} onClick={() => navigate("/console/offlineregister")} /></span>
                </>
              )}
            </>
          )}
          </div>
        </Col>
          </Row>
          </Container>  
        
        </>
        )}
        </Col>
     
      
   
          
           
            <ToastContainer
                          position="top-center"
                          autoClose={2000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="colored"
                        />
            {error && (
              <Col lg='12' md='12' xs='12' className='py-2'>
                <Alert variant='danger' >
                  {error}
                </Alert>
              </Col>
            )}
          </Row>
        </Container>}
    </div >
  )
}


export default OfflineCreation