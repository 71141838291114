import React from 'react'
import { MdArrowBack } from "react-icons/md";
import {Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const PageNav = ({pagetitle}) => {
  const navigate=useNavigate();
  return (
    <div className='page-nav d-flex align-items-center'>
        <div>
            <Button className='Button'onClick={()=>navigate(-1)}><MdArrowBack /></Button>
        </div>
        <div className='nav-list'>{pagetitle}</div>
    </div>
  )
}

export default PageNav