import React, { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import MobileView from '../components/MobileView';
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from '../components/Table';
import Pagination from '../components/Pagnation';
import { ClickButton } from '../components/ClickButton';
import { TextInputForm } from '../components/Forms';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';



const UserTablehead = ["No", "Plan Name", "Price", "Action",]

const Plans = () => {

  const navigate = useNavigate();


  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/plan.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setUserData(responseData.body.plans);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg='7' md='9' xs='6'>
            <div className='page-nav py-3'>
              <span class="nav-list"> Plans</span>
            </div>
          </Col>
          <Col lg='5' md='3' xs='6' className='align-self-center text-end'>
            <ClickButton label="Add New" onClick={() => navigate("create")} />
          </Col>
          <Col lg='3' md='5' xs='12' className='py-1'>
            <TextInputForm placeholder={"Plan Name"} prefix_icon={<FaMagnifyingGlass />} labelname={"Plan Name"}> </TextInputForm>
          </Col>
          <Col lg={9} md={12} xs={12} className='py-2'>
            <Pagination totalItems={userData.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
          </Col>
          <Col lg='12' md='12' xs='12' className='px-0'>
            <div className='py-1'>
              {/* <MobileView sno={userData.id} name={userData.plan_name} subname={userData.plan_price} /> */}
              {loading ? <center><Spinner animation="border" variant="dark" /> </center> :
                <TableUI headers={UserTablehead} body={userData} type="plan" style={{ 'borderRadius': '5px' }} />}
            </div>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <Pagination totalItems={userData.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Plans