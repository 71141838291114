import React from 'react';
import { Table, Button, Dropdown } from 'react-bootstrap';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';

const TableUI = ({ headers, body, style, type, rowData, planViewAction }) => {
  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    navigate("/console/user/create", { state: { type: "view", rowData: rowData } });
  };
  const handleEditClick = (rowData) => {
    navigate("/console/user/create", { state: { type: "edit", rowData: rowData } });
  }
  const handleDeleteClick = async (id) => {

    try {
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_user_id: id
        })
      });

      const responseData = await response.json();



      if (responseData.head.code === 200) {
        navigate("/console/user");
        window.location.reload();
      } else {

      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePlanViewClick = (rowData) => {
    navigate("/console/plans/create", { state: { type: "view", rowData: rowData } });
  };
  const handlePlanEditClick = (rowData) => {
    navigate("/console/plans/create", { state: { type: "edit", rowData: rowData } });
  }
  const handlePlanDeleteClick = async (id) => {

    try {
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/plan.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_plan_id: id
        })
      });

      const responseData = await response.json();



      if (responseData.head.code === 200) {
        navigate("/console/plans");
        window.location.reload();
      } else {

      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleOffViewClick = (rowData) => {
    navigate("/console/offlineregister/create", { state: { type: "offline", rowData: rowData } });
  };
  const handleOffEditClick = (rowData) => {
    navigate("/console/offlineregister/create", { state: { type: "offffedit", rowData: rowData } });
  }
  const handleOffDeleteClick = async (customer_id) => {

    try {
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/offline_register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          delete_customer_id: customer_id
        })
      });

      const responseData = await response.json();


      if (responseData.head.code === 200) {
        navigate("/console/offlineregister");
        window.location.reload();
      } else {

      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleOnlineViewClick = (rowData) => {
    navigate("/console/onlineregister/create", { state: { type: "online", rowData: rowData } });
  };



  return (
    <Table responsive="md" style={style}>
      <thead>
        <tr className='notshow'>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {type === "USER" && ( // Checking if type is "USER"
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.name}</td>
                <td>{rowData.role}</td>
                <td className="created-datetime">{rowData.created_datetime}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleViewClick(rowData)}>View</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "Report" && ( // Checking if type is "USER"
              <> {/* Fragment shorthand */}
                <td>{rowData.customer_id}</td>
                <td>{rowData.type_of_register}</td>
                <td>{rowData.id_verfication === 'yes' ? 'Verified' : 'Unverified'}</td>
                <td>{rowData.gender}</td>
                <td>{rowData.first_name}</td>
                <td>{rowData.phone_number}</td>
                <td>{rowData.email}</td>
                <td>{rowData.membership_tag ? rowData.membership_tag : 'Free'}</td>
              </>
            )}
            {type === "plan" && (
              <> {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.plan_name}</td>
                <td className='plan_price'>{rowData.plan_price}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handlePlanViewClick(rowData)}>View</Dropdown.Item>
                      <Dropdown.Item onClick={() => handlePlanEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handlePlanDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "online_register" && ( // Rendering when type is not "USER"
              <>
                <td>{rowData.customer_id}</td>
                <td>{rowData.first_name}</td>
                <td>{rowData.phone_number}</td>
                <td className='city_id'>{rowData.city_id}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleOnlineViewClick(rowData)}>View</Dropdown.Item>
                      {/* <Dropdown.Item href="#/action-2">Edit</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Delete</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "OFFOR" && ( // Rendering when type is not "USER"
              <>
                <td>{rowData.customer_id}</td>
                <td>{rowData.first_name}</td>
                <td>{rowData.phone_number}</td>
                <td className='city_id'>{rowData.city_id}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleOffViewClick(rowData)}>View</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOffEditClick(rowData)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOffDeleteClick(rowData.customer_id)}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}

            {type === "KYC" && ( // Rendering when type is not "USER"
              <>
                <td>{rowData.customer_id}</td>
                <td>{rowData.first_name}</td>
                <td>{rowData.phone_number}</td>
                <td>{rowData.city_id}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className='action'><BiDotsVerticalRounded /></Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">View</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">Edit</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}


          </tr>
        ))}
      </tbody>
    </Table >
  );
};

export default TableUI;
