import React, { useState, useEffect, useCallback } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { InstantCreate } from './ClickButton'
import { BiPlus } from "react-icons/bi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TextInputForm = ({ placeholder, name, type, suffix_icon, prefix_icon, labelname, value, onChange,onBlur }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='form-icon'>
        <Form.Group className="">
          {prefix_icon ? <span className='prefix-icon'>{prefix_icon}</span> : ""}
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            className={`form-cntrl w-100 ${prefix_icon && suffix_icon ? "form-control-padboth" : prefix_icon ? "form-control-padleft" : suffix_icon ? "form-control-padright" : ""}`}
            value={value}
            onChange={onChange}
            onBlur ={onBlur}
          />
          {suffix_icon ? <span className='suffix-icon'>{suffix_icon}</span> : ""}
        </Form.Group>
      </div>
    </>
  )
}
const DropDown = ({ placeholder, optionlist, labelname }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='w-100 d-flex'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField='lable'
          valueField='values'
          multi className='w-100'>
        </Select>
        <InstantCreate label={<BiPlus />} className='instant-add' onClick={console.log('I was triggered during render')}></InstantCreate>
      </div>
    </>

  )
}
const DropDownUI = ({ optionlist = [], name, labelname, placeholder, value, onChange }) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue
      });
    }
  };

  // Find the selected option based on the current value
  const selectedOption = optionlist.find(option => option.value === value);

  return (
    <>
      <div className='pb-2 px-3'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>

      <div className='w-100 d-flex'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField='label'
          valueField='value'
          value={selectedOption} // Set the selected option
          onChange={handleChange}
          multi
          className='w-100'
        />

        {/* <InstantCreate label={<BiPlus />} className='instant-add' onClick={handleShowModal}></InstantCreate>
              <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header >
                      <Modal.Title>{modeltitle} {labelname ? labelname : "Model"}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <TextInputForm placeholder={placeholder} labelname={labelname} />
                  </Modal.Body>
                  <Modal.Footer>
                      <ClickButton label={<>Cancel</>} onClick={handleCloseModal} >
                          Close
                      </ClickButton>
                      <ClickButton label={<>Submit</>} onClick={handleCloseModal} >
                          Save Changes
                      </ClickButton>
                  </Modal.Footer>
              </Modal> */}
      </div>
    </>
  );
};
// const DropDownUI = ({ optionlist = [], className, name, labelname, placeholder, value, onChange }) => {
//   const handleChange = (selectedOption) => {
//       const selectedValue = selectedOption.value;
//       if (selectedValue !== value) {
//           onChange({
//               ...value,
//               [name]: selectedValue
//           });

//       }
//   };
//   return (
//     <>
//       <div className='pb-2 px-3'>
//         {labelname ? <label>{labelname}</label> : ""}
//       </div>

//       <Select
//         placeholder={placeholder}
//         options={optionlist}
//         labelField='title'
//         valueField='value'
//         onChange={handleChange}
//         multi className='w-100'>
//       </Select>
//     </>

//   );
// };

// const DropDownUI = ({ optionlist = [], className, labelname, placeholder, value, onChange }) => {
//   const handleChange = (selectedOption) => {
//     const selectedValue = selectedOption.value;

//     if (selectedValue !== value) {
//       onChange({ name: "role", value: selectedValue });
//     }
//   };

//   return (
//     <>
//       <div className='pb-2 px-3'>
//         {labelname ? <label>{labelname}</label> : ""}
//       </div>
//       <Select
//         placeholder={placeholder}
//         options={optionlist}
//         labelField='label'
//         valueField='value'
//         // value={optionlist.find(option => option.value === value)}
//         onChange={handleChange}
//         className='w-100'
//       />
//     </>
//   );
// };


const Calender = ({ setLabel, calenderlabel }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <div className='pb-2 px-3'>
        <label>{calenderlabel}</label>
      </div>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setLabel(date, 'date_of_birth'); // Pass 'date_of_birth' as the field name
        }}
        dateFormat='dd/MM/yyyy'
        className="w-100 form-cntrl"
        selectsStart
        startDate={startDate}
      />
    </>
  );
};

// const Calender = () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return (
//     <>
//       <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat='dd/MM/yyyy' className="w-100 form-cntrl" />
//     </>
//   )
// }
export { TextInputForm, DropDown, Calender, DropDownUI }