import React, { useState } from 'react'
import { Route, Routes, NavLink, useNavigate } from 'react-router-dom';
import DashBoard from '../../pages/DashBoard';
import User from '../../pages/User'
import OfflineRegister from '../../pages/OfflineRegister';
import OnlineRegister from '../../pages/OnlineRegister';
import UserCreation from '../../pages/UserCreation'
import { LuDot } from "react-icons/lu";
import { Button, Container } from 'react-bootstrap';
import { MdOutlineDashboard, MdOutlinePerson, MdOutlineStorefront } from "react-icons/md";
import { MdLanguage } from "react-icons/md";
import { MdChecklistRtl } from "react-icons/md";
import OfflineCreation from '../../pages/OfflineCreation';
import { MdLogout } from "react-icons/md";
import Navbar from 'react-bootstrap/Navbar';
import Plans from '../../pages/Plans';
import PlansCreation from '../../pages/planscreation';
import OfflineReport from '../../pages/OfflineReport';
import OnlineCreation from '../../pages/OnlineCreation';

const SideBar = ({ onLogout }) => {
  const [isDeactive, SetDeactive] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };
  const toggle = () => { SetDeactive(!isDeactive ? 'remove' : ''); };
  return (
    <>
      <Navbar className='navfix navbg '>
        <Container fluid>
          <Button className="menu-toggle" onClick={toggle} id="menu-toggle">
            <span class="navbar-toggler-icon"></span>
          </Button>
          <div className='user-logo me-auto'>
            <img src={require('../sidebar/images/logo.png')} className='img-fluid logo' alt="" />
          </div>
          <div className='ms-auto user-id'>
            <MdOutlinePerson />
          </div>
        </Container>
      </Navbar>
      <div className={` ${isDeactive ? "wrap-remove" : ""}`} id="sidebar-wrapper">
        <div className="list-group regular">
          <ul>
            <li>
              <NavLink to="/dashboard" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlineDashboard /></span>
                <span class="list-text">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/user" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlinePerson /></span>
                <span class="list-text">User</span>
              </NavLink>
            </li>


            <li>
              <NavLink to="/console/onlineregister" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdLanguage /></span>
                <span class="list-text">Online Register</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/offlineregister" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdOutlineStorefront /></span>
                <span class="list-text">Offline Register</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/plans" className="nav-link " onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><MdChecklistRtl /></span>
                <span class="list-text">Plans</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/report" className="nav-link" onClick={window.innerWidth <= 768 ? toggle : null}>
                <span className="list-icon"><LuDot /></span>
                <span class="list-text"> Reports</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="log-out">
          <div className="list-group">
            <ul>
              <li>
                <Button onClick={handleLogout}>
                  <span className="list-icon"><MdLogout /></span>
                  <span class="list-text">Logout</span>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* main-content start */}
      <div id="page-content-wrapper" className={`${isDeactive ? "page-remove" : ""}`}>
        <div className='content-bg'>
          <div className="px-lg-4 py-4">
            <div class="main-content">
              <Routes>
                <Route path='/dashboard' element={<DashBoard />}></Route>
                <Route path='/console/user' element={<User />}></Route>
                <Route path='/console/user/create' element={<UserCreation />}></Route>
                <Route path='/console/onlineregister' element={<OnlineRegister />}></Route>
                <Route path='/console/onlineregister/create' element={<OnlineCreation />}></Route>
                <Route path='/console/offlineregister' element={<OfflineRegister />}></Route>
                <Route path='/console/offlineregister/create' element={<OfflineCreation />}></Route>
                <Route path='/console/plans' element={<Plans />}></Route>
                <Route path='/console/plans/create' element={<PlansCreation />}></Route>
                <Route path='/console/report' element={<OfflineReport />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
      {/* main-content-end */}
    </>
  )
}

export default SideBar