import React, { useState } from 'react'
import { Container, Col, Row, Alert } from 'react-bootstrap'
import { TextInputForm, DropDownUI, Calender } from '../components/Forms'
import PageNav from '../components/PageNav';
import Preference from './Preference';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { ClickButton } from '../components/ClickButton';
import Spinner from 'react-bootstrap/Spinner';
const OnlineCreation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { type, rowData } = location.state || {};

    const stateOptions = [
        {
            value: "TN",
            label: "Tamil Nadu",
            country: 'india'
        },
        {
            value: "PY",
            label: "Pondycheri",
            country: 'india'
        },
        {
            value: "KL",
            label: "Kerala",
            country: 'india'
        }, {
            value: "TL",
            label: "Telugana",
            country: 'india'
        }
    ];
    const cityOptions = [
        {
            value: "Ariyalur",
            label: "Ariyalur",
            state: 'TN'
        },
        {
            value: "Chengalpattu",
            label: "Chengalpattu",
            state: 'TN'
        },
        {
            value: "Chennai",
            label: "Chennai",
            state: 'TN'
        },
        {
            value: "Coimbatore",
            label: "Coimbatore",
            state: 'TN'
        },
        {
            value: "Cuddalore",
            label: "Cuddalore",
            state: 'TN'
        },
        {
            value: "Dharmapuri",
            label: "Dharmapuri",
            state: 'TN'
        },
        {
            value: "Dindigul",
            label: "Dindigul",
            state: 'TN'
        },
        {
            value: "Erode",
            label: "Erode",
            state: 'TN'
        },
        {
            value: "Kallakurichi",
            label: "Kallakurichi",
            state: 'TN'
        },
        {
            value: "Kancheepuram",
            label: "Kancheepuram",
            state: 'TN'
        },
        {
            value: "Karur",
            label: "Karur",
            state: 'TN'
        },
        {
            value: "Krishnagiri",
            label: "Krishnagiri",
            state: 'TN'
        },
        {
            value: "Madurai",
            label: "Madurai",
            state: 'TN'
        },
        {
            value: "Nagapattinam",
            label: "Nagapattinam",
            state: 'TN'
        },
        {
            value: "Namakkal",
            label: "Namakkal",
            state: 'TN'
        },
        {
            value: "Nilgiris",
            label: "Nilgiris",
            state: 'TN'
        },
        {
            value: "Perambalur",
            label: "Perambalur",
            state: 'TN'
        },
        {
            value: "Pudukkottai",
            label: "Pudukkottai",
            state: 'TN'
        },
        {
            value: "Ramanathapuram",
            label: "Ramanathapuram",
            state: 'TN'
        },
        {
            value: "Ranipet",
            label: "Ranipet",
            state: 'TN'
        },
        {
            value: "Salem",
            label: "Salem",
            state: 'TN'
        },
        {
            value: "Sivagangai",
            label: "Sivagangai",
            state: 'TN'
        },
        {
            value: "Tenkasi",
            label: "Tenkasi",
            state: 'TN'
        },
        {
            value: "Thanjavur",
            label: "Thanjavur",
            state: 'TN'
        },
        {
            value: "Theni",
            label: "Theni",
            state: 'TN'
        },
        {
            value: "Tuticorin",
            label: "Tuticorin",
            state: 'TN'
        },
        {
            value: "Tiruchirappalli",
            label: "Tiruchirappalli",
            state: 'TN'
        },
        {
            value: "Tirunelveli",
            label: "Tirunelveli",
            state: 'TN'
        },
        {
            value: "Tirupathur",
            label: "Tirupathur",
            state: 'TN'
        },
        {
            value: "Tiruppur",
            label: "Tiruppur",
            state: 'TN'
        },
        {
            value: "Tiruvallur",
            label: "Tiruvallur",
            state: 'TN'
        },
        {
            value: "Tiruvannamalai",
            label: "Tiruvannamalai",
            state: 'TN'
        },
        {
            value: "Tiruvarur",
            label: "Tiruvarur",
            state: 'TN'
        },
        {
            value: "Vellore",
            label: "Vellore",
            state: 'TN'
        },
        {
            value: "Viluppuram",
            label: "Viluppuram",
            state: 'TN'
        },
        {
            value: "Virudhunagar",
            label: "Virudhunagar",
            state: 'TN'
        },
        {
            value: "Karaikal",
            label: "Karaikal",
            state: 'PY'
        },
        {
            value: "Mahe",
            label: "Mahe",
            state: 'PY'
        },
        {
            value: "Yanam",
            label: "Yanam",
            state: 'PY'
        },
        {
            value: "Alappuzha",
            label: "Alappuzha",
            state: 'KL'
        },
        {
            value: "Ernakulam",
            label: "Ernakulam",
            state: 'KL'
        },
        {
            value: "Idukki",
            label: "Idukki",
            state: 'KL'
        },
        {
            value: "Kottayam",
            label: "Kottayam",
            state: 'KL'
        },
        {
            value: "Kannur",
            label: "Kannur",
            state: 'KL'
        },
        {
            value: "Kasaragod",
            label: "Kasaragod",
            state: 'KL'
        },
        {
            value: "Thrissur",
            label: "Thrissur",
            state: 'KL'
        },
        {
            value: "Malappuram",
            label: "Malappuram",
            state: 'KL'
        },
        {
            value: "Kozhikode",
            label: "Kozhikode",
            state: 'KL'
        },
        {
            value: "Pathanamthitta",
            label: "Pathanamthitta",
            state: 'KL'
        },
        {
            value: "Thiruvananthapuram",
            label: "Thiruvananthapuram",
            state: 'KL'
        },
        {
            value: "Palakkad",
            label: "Palakkad",
            state: 'KL'
        },
        {
            value: "Kollam",
            label: "Kollam",
            state: 'KL'
        },
        {
            value: "Adilabad",
            label: "Adilabad",
            state: 'TL'
        },
        {
            value: "Bhadradri Kothagudem",
            label: "Bhadradri Kothagudem",
            state: 'TL'
        },
        {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        }, {
            value: "",
            label: "",
            state: 'TL'
        },
    ];
    const countryOptions = [
        {
            value: "India",
            label: "India"
        },
        {
            value: "usa",
            label: "USA"
        }
    ];
    const GenderList = [
        {
            value: "Male",
            label: "Male"
        },
        {
            value: "Female",
            label: "Female"
        }
    ];
    const MaterialList = [
        {
            value: "Any",
            label: "Any"
        },
        {
            value: "Single",
            label: "Single"
        },
        {
            value: "Waiting-Divorced",
            label: "Waiting-Divorced"
        },
        {
            value: "Divorced",
            label: "Divorced"
        },
        {
            value: "Widowed",
            label: "Widowed"
        },
        {
            value: "orphanage",
            label: "orphanage"
        }
    ];
    const LanguageList = [
        {
            value: "Tamil",
            label: "Tamil"
        },
        {
            value: "Telugu",
            label: "Telugu"
        },
        {
            value: "Kannada",
            label: "Kannada"
        },
        {
            value: "Garo",
            label: "Garo"
        },
        {
            value: "Hindi",
            label: "Hindi"
        },
        {
            value: "Malayalam",
            label: "Malayalam"
        },
        {
            value: "Marathi",
            label: "Marathi"
        }
    ];
    const EatingList = [
        {
            value: "Doesn't Matter",
            label: "Doesn't Matter"
        },
        {
            value: "vegtarian",
            label: "vegtarian"
        },
        {
            value: "Non Vegetarian",
            label: "Non Vegetarian"
        },
        {
            value: "Eggetarian",
            label: "Eggetarian"
        }

    ];

    const ReligiousList = [
        {
            value: "Hindu",
            label: "Hindu"
        },
        {
            value: "Christians",
            label: "Christians"
        },
        {
            value: "Muslims",
            label: "Muslims"
        }

    ];
    const CasteList = [
        {
            value: "Arunthathiyar",
            label: "Arunthathiyar"
        },
        {
            value: "Brahmin",
            label: "Brahmin"
        },
        {
            value: "Chettiyar",
            label: "Chettiyar"
        },

        {
            value: "Devar",
            label: "Devar"
        },
        {
            value: "Gowda",
            label: "Gowda"
        },
        {
            value: "Gounder",
            label: "Gounder"
        },
        {
            value: "Nayudu",
            label: "Nayudu"
        },
        {
            value: "Nadar",
            label: "Nadar"
        },
        {
            value: "Adi Dravidar",
            label: "Adi Dravidar"
        }

    ];
    const EducationList = [
        {
            value: "school",
            label: "School"
        },
        {
            value: "highSchool",
            label: "High School"
        },
        {
            value: "college",
            label: "College"
        },
        {
            value: "university",
            label: "University"
        },
        {
            value: "eexxel",
            label: "eexxel"
        }


    ];
    const EducationDetailsList = [
        {
            value: "school",
            label: "School"
        },
        {
            value: "highSchool",
            label: "High School"
        },
        {
            value: "diploma",
            label: "Diploma"
        },
        {
            value: "associateDegree",
            label: "Associate Degree"
        },
        {
            value: "bachelorDegree",
            label: "Bachelor's Degree"
        },
        {
            value: "masterDegree",
            label: "Master's Degree"
        },
        {
            value: "doctorate",
            label: "Doctorate (Ph.D.)"
        },
        {
            value: "professionalDegree",
            label: "Professional Degree"
        }
    ];

    const initialState = type === 'offffedit' ? { ...rowData } : {
        gender: "",
        first_name: "",
        phone_number: "",
        age: "",
        height_feet: "",
        weight: "",
        physical_status: "",
        marital_status: "",
        mother_tongue: "",
        habits: "",
        religious_status: "",
        caste: "",
        star: "",
        rasi: "",
        dosam: "",
        country: "",
        pincode: "",
        state: "",
        city: "",
        education: "",
        empoly_in: "",
        occupation: "",
        about: "",
        gov_id: "",
        selfie: "",
        education_certificate: "",
        salary_slip: "",
        plan: "",
        email: "",
        password: "",
        date_of_birth: "",
        profile_picture: "",
        profile_created_by: "",
        prefer_location: "",
        prefer_age: "",
        prefer_height: "",
        prefer_language: "",
        prefer_physical_status: "",
        prefer_eating_habits: "",
        prefer_religious: "",
        prefer_caste: "",
        prefer_dhosam: "",
        prefer_star: "",
        prefer_raasi: "",
        prefer_education: "",
        prefer_employedin: "",
        prefer_occupation: "",
        prefer_annual_income: "",
        prefer_looking: "",
    };

    const [formData, setFormData] = useState(initialState);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setFormData({
            ...formData,
            [fieldName]: value
        });
    };

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedState(stateOptions);
        setSelectedCity(null);
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };
    
    const setLabel = (date, label) => {
        handleChange(label, date);
    };

    return (
        <div className='regular main-content'>
            {loading ? <center><Spinner animation="border" variant="dark" /> </center> :
                <Container>

                    <Row>
                        <Col lg='12' className='py-3'>
                            <PageNav pagetitle={'Online View'}></PageNav>
                        </Col>
                        <Col lg='12'>
                            <div className='py-3'> Verification</div>
                        </Col>
                        <Col lg='6' className='py-3'>
                            <div className='aadhaar w-100'>
                                <div className=' w-100'><TextInputForm labelname={<>Aadhaar No.</>} /></div>
                                <div className='mx-3'><ClickButton label={<>Verify</>} /></div>
                            </div>
                        </Col>
                        <Col lg='6' className='py-3 align-self-center'> </Col>
                        <Col lg='6' className='py-3 align-self-center'> <div> Basic Details</div> </Col>
                        <Col lg='6' className='py-3 align-self-center'>
                            <div class="file-upload text-end">
                                <input type="file" id="upload" accept=".jpg, .jpeg, .png" />
                                <label for="upload" class="my-2 upload text-white">Upload Your Image</label>
                            </div>
                        </Col>
                        <Col lg="3" md='3' xs='12' className='py-3'>
                            <DropDownUI placeholder="Select"
                                labelname="Profile Created "></DropDownUI>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <TextInputForm
                                    placeholder={'Name'}
                                    labelname={'Name'}
                                    name='first_name'
                                    value={formData.first_name}
                                    onChange={(e) => handleChange(e, 'first_name')}
                                />
                            ) : (
                                <TextInputForm
                                    placeholder={'Name'}
                                    labelname={'Name'}
                                    name='first_name'
                                    value={type === "online" ? rowData.first_name : formData.first_name}
                                    onChange={(e) => handleChange(e, 'first_name')}
                                    disabled={type === "offline"}
                                />
                            )}
                        </Col>
                        <Col lg="3" md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <DropDownUI
                                    optionlist={GenderList}
                                    name="gender"
                                    placeholder="Select"
                                    labelname="Gender Select"
                                    value={formData.gender}
                                    onChange={(updatedFormData) => setFormData({ ...formData, gender: updatedFormData.gender })}>
                                </DropDownUI>
                            ) : (
                                <DropDownUI
                                    optionlist={GenderList}
                                    name="gender"
                                    placeholder="Select"
                                    labelname="Gender Select"
                                    value={type === "online" ? rowData.gender : formData.gender}
                                    onChange={(updatedFormData) => handleChange(updatedFormData, 'gender')}>
                                </DropDownUI>
                            )}

                        </Col>

                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                <Calender setLabel={(date) => setLabel(date, 'fromDate')}
                                    value={type === 'online' ? rowData.date_of_birth : formData.date_of_birth}
                                    name="date_of_birth"
                                    calenderlabel="Date of Birth" />
                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <TextInputForm placeholder={"Height"}
                                    labelname="Height Select"
                                    name="height_feet"
                                    value={formData.height_feet}
                                    onChange={(e) => handleChange(e, 'height_feet')} />
                            ) : (
                                <TextInputForm placeholder={"Height"}
                                    labelname="Height Select"
                                    name="height_feet"
                                    value={type === 'online' ? rowData.height_feet : formData.height_feet}
                                    onChange={(e) => handleChange(e, 'height_feet')} />
                            )}
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <TextInputForm placeholder={"Enter Mob No."}
                                    name="phone_number"
                                    value={formData.phone_number}
                                    onChange={(e) => handleChange(e, 'phone_number')}
                                    labelname={"Enter Mobile No."} />
                            ) : (
                                <TextInputForm placeholder={"Enter Mob No."}
                                    name="phone_number"
                                    value={type === 'online' ? rowData.phone_number : formData.phone_number}
                                    onChange={(e) => handleChange(e, 'phone_number')}
                                    labelname={"Enter Mobile No."} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "edit" ? (
                                <TextInputForm placeholder={"Father Info"}
                                    name="father_info"
                                    value={formData.father_info}
                                    onChange={(e) => handleChange(e, 'mobile_number')}
                                    labelname={"Father Info"} />
                            ) : (
                                <TextInputForm placeholder={"Father Info"}
                                    name="father_info"
                                    value={type === "online" ? rowData.father_info : formData.father_info}
                                    onChange={(e) => handleChange(e, 'mobile_number')}
                                    labelname={"Father Info"} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "edit" ? (
                                <TextInputForm placeholder={"Mother Info"}
                                    name="mother_info"
                                    value={formData.mother_info}
                                    onChange={(e) => handleChange(e, 'mother_info')}
                                    labelname={"Mother Info"} />
                            ) : (
                                <TextInputForm placeholder={"Mother Info"}
                                    name="mother_info"
                                    value={type === 'online' ? rowData.mother_info : formData.mother_info}
                                    onChange={(e) => handleChange(e, 'mother_info')}
                                    labelname={"Mother Info"} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <TextInputForm placeholder={"Enter the email"}
                                    type={"email"}
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => handleChange(e, 'email')}
                                    labelname={"Enter the email"} />
                            ) : (
                                <TextInputForm placeholder={"Enter the email"}
                                    type={"email"}
                                    name="email"
                                    value={type === "online" ? rowData.email : formData.email}
                                    onChange={(e) => handleChange(e, 'email')}
                                    labelname={"Enter the email"} />
                            )}
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === 'offffdit' ? (
                                <TextInputForm placeholder={"Physical Status"}
                                    name="physical_status"
                                    value={formData.physical_status}
                                    onChange={(e) => handleChange(e, 'physical_status')}
                                    labelname={"Physical status"} />
                            ) : (
                                <TextInputForm placeholder={"Physical Status"}
                                    name="physical_status"
                                    value={type === 'online' ? rowData.physical_status : formData.physical_status}
                                    onChange={(e) => handleChange(e, 'physical_status')}
                                    labelname={"Physical status"} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === 'offffedit' ? (
                                    <DropDownUI optionlist={MaterialList}
                                        placeholder={"Marital Status"}
                                        name="marital_status"
                                        value={formData.marital_status}
                                        labelname={"Marital Status"}
                                        onChange={(updatedFormData) => setFormData({ ...formData, marital_status: updatedFormData.marital_status })} />
                                ) : (
                                    <DropDownUI optionlist={MaterialList}
                                        placeholder={"Marital Status"}
                                        name="marital_status"
                                        value={type === 'online' ? rowData.marital_status : formData.marital_status}
                                        labelname={"Marital Status"}
                                        onChange={(updatedFormData) => setFormData(updatedFormData, 'marital_status')} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <DropDownUI optionlist={LanguageList}
                                    placeholder={"Mother Tounge"}
                                    name="mother_tongue"
                                    value={formData.mother_tongue}
                                    labelname={"Mother Tounge"}
                                    onChange={(updatedFormData) => setFormData({ ...formData, mother_tongue: updatedFormData.mother_tongue })} />
                            ) : (
                                <DropDownUI optionlist={LanguageList}
                                    placeholder={"Mother Tounge"}
                                    name="mother_tongue"
                                    value={type === 'online' ? rowData.mother_tongue : formData.mother_tongue}
                                    labelname={"Mother Tounge"}
                                    onChange={(updatedFormData) => setFormData(updatedFormData, 'mother_tongue')} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <DropDownUI optionlist={EatingList}
                                    placeholder={"Eating Habits"}
                                    name="habits"
                                    value={formData.habits}
                                    labelname={"Eating habits"}
                                    onChange={(updatedFormData) => setFormData({ ...formData, habits: updatedFormData.habits })} />
                            ) : (
                                <DropDownUI optionlist={EatingList}
                                    placeholder={"Eating Habits"}
                                    name="habits"
                                    value={type === "online" ? rowData.habits : formData.habits}
                                    labelname={" habits"}
                                    onChange={(updatedFormData) => setFormData(updatedFormData, 'habits')} />
                            )}


                        </Col>
                        <Col lg='12' md='12' xs='12' className='py-3'>
                            <div>Religious Details</div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <DropDownUI optionlist={ReligiousList}
                                    placeholder={"Enter Your Religious"}
                                    name="religious_status"
                                    value={formData.religious_status}
                                    labelname={"Enter Your Religious"}
                                    onChange={(updatedFormData) => setFormData({ ...formData, religious_status: updatedFormData.religious_status })} />
                            ) : (
                                <DropDownUI optionlist={ReligiousList}
                                    placeholder={"Enter Your Religious"}
                                    name="religious_status"
                                    value={type === "online" ? rowData.religious_status : formData.religious_status}
                                    labelname={"Enter Your Religious"}
                                    onChange={(updatedFormData) => setFormData(updatedFormData, 'religious_status')} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <DropDownUI optionlist={CasteList}
                                    placeholder={"caste"} name="caste"
                                    labelname={"caste"}
                                    value={formData.caste}
                                    onChange={(updatedFormData) => setFormData({ ...formData, caste: updatedFormData.caste })} />
                            ) : (
                                <DropDownUI optionlist={CasteList}
                                    placeholder={"caste"} name="caste"
                                    labelname={"caste"}
                                    value={type === "online" ? rowData.caste : formData.caste}
                                    onChange={(updatedFormData) => setFormData(updatedFormData, 'caste')} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <TextInputForm placeholder={"Star"}
                                        name="star"
                                        onChange={(e) => handleChange(e, 'star')}
                                        value={formData.star}
                                        labelname={"Star"} />
                                ) : (
                                    <TextInputForm placeholder={"Star"}
                                        name="star"
                                        onChange={(e) => handleChange(e, 'star')}
                                        value={type === "online" ? rowData.star : formData.star}
                                        labelname={"Star"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            {type === "offffedit" ? (
                                <TextInputForm placeholder={"Raasi"}
                                    name="rasi"
                                    onChange={(e) => handleChange(e, 'rasi')}
                                    value={formData.rasi}
                                    labelname={"Raasi"} />
                            ) : (
                                <TextInputForm placeholder={"Raasi"}
                                    name="rasi"
                                    onChange={(e) => handleChange(e, 'rasi')}
                                    value={type === 'online' ? rowData.rasi : formData.rasi}
                                    labelname={"Raasi"} />
                            )}


                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <TextInputForm placeholder={"Dhosam"}
                                        name="dosam"
                                        onChange={(e) => handleChange(e, 'dosam')}
                                        value={formData.dosam}
                                        labelname={"Dhosam"} />
                                ) : (
                                    <TextInputForm placeholder={"Dhosam"}
                                        name="dosam"
                                        onChange={(e) => handleChange(e, 'dosam')}
                                        value={type === 'online' ? rowData.dosam : formData.dosam}
                                        labelname={"Dhosam"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='12' md='12' xs='12' className='py-3'>
                            <div>Location Details</div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <TextInputForm placeholder={"Address"}
                                        name="address"
                                        value={formData.address}
                                        onChange={(e) => handleChange(e, 'address')}
                                        labelname={"Address"} />
                                ) : (
                                    <TextInputForm placeholder={"Address"}
                                        name="address"
                                        value={type === "online" ? rowData.address : formData.address}
                                        onChange={(e) => handleChange(e, 'address')}
                                        labelname={"Address"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <TextInputForm placeholder={"City"}
                                        name="city_id"
                                        value={formData.city_id}
                                        onChange={(e) => handleChange(e, 'city_id')}
                                        labelname={"City"} />
                                ) : (
                                    <TextInputForm placeholder={"City"}
                                        name="city_id"
                                        value={type === "online" ? rowData.city_id : formData.city_id}
                                        onChange={(e) => handleChange(e, 'city_id')}
                                        labelname={"City"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <TextInputForm placeholder={"Pincode"}
                                        name="pincode"
                                        onChange={(e) => handleChange(e, 'pincode')}
                                        value={formData.pincode}
                                        labelname={"pincode"} />
                                ) : (
                                    <TextInputForm placeholder={"Pincode"}
                                        name="pincode"
                                        onChange={(e) => handleChange(e, 'pincode')}
                                        value={type === "online" ? rowData.pincode : formData.pincode}
                                        labelname={"pincode"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                <DropDownUI
                                    optionlist={countryOptions}
                                    placeholder={"Select Country"}
                                    name="country"
                                    labelname={"country"}
                                    value={selectedCountry}
                                    onChange={(e) => handleChange(e, 'country')}
                                />
                            </div>
                        </Col>

                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {selectedCountry && (
                                    <Select
                                        value={selectedState}
                                        onChange={(e) => handleChange(e, 'state')}
                                        options={stateOptions}
                                        name="state"
                                        placeholder="Select State"
                                    />
                                )}
                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {selectedState && (
                                    <Select
                                        value={selectedCity}
                                        onChange={(e) => handleChange(e, 'city')}
                                        options={cityOptions}
                                        name="city"
                                        placeholder="Select City"
                                    />
                                )}
                            </div>
                        </Col>
                        <Col lg='12' md='12' xs='12' className='py-3'>
                            <div>Professional Details</div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <DropDownUI optionlist={EducationList}
                                        name="education"
                                        value={formData.education}
                                        onChange={(e) => handleChange(e, 'education')}
                                        placeholder={"Education"}
                                        labelname={"Education"} />
                                ) : (
                                    <DropDownUI optionlist={EducationList}
                                        name="education"
                                        value={type === "online" ? rowData.education : formData.education}
                                        onChange={(e) => handleChange(e, 'education')}
                                        placeholder={"Education"}
                                        labelname={"Education"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offffedit" ? (
                                    <DropDownUI placeholder={"Employed In"}
                                        name="empoly_in"
                                        value={formData.empoly_in}
                                        onChange={(e) => handleChange(e, 'employ_in')}
                                        labelname={"Employed In"} />
                                ) : (
                                    <DropDownUI placeholder={"Employed In"}
                                        name="empoly_in"
                                        value={type === "online" ? rowData.empoly_in : formData.empoly_in}
                                        onChange={(e) => handleChange(e, 'employ_in')}
                                        labelname={"Employed In"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='3' md='3' xs='12' className='py-3'>
                            <div>
                                {type === "offfedit" ? (
                                    <TextInputForm placeholder={"Occupation"}
                                        name="occupation"
                                        value={formData.occupation}
                                        onChange={(e) => handleChange(e, 'occuaption')}
                                        labelname={"Occupation"} />
                                ) : (
                                    <TextInputForm placeholder={"Occupation"}
                                        name="occupation"
                                        value={type === "online" ? rowData.occupation : formData.occupation}
                                        onChange={(e) => handleChange(e, 'occuaption')}
                                        labelname={"Occupation"} />
                                )}

                            </div>
                        </Col>
                        <Col lg='12' className='py-3'> <div> About Yourself</div></Col>
                        <Col lg='6' className='py-3'>
                            {type === "offffedit" ? (
                                <div className='w-100'>
                                    <textarea className='form-cntrl w-100'
                                        value={formData.about_me}
                                        onChange={(e) => handleChange(e, 'about_me')}
                                        name="about_me"
                                        placeholder='About'
                                        labelname={"About"} />
                                </div>
                            ) : (
                                <div className='w-100'>
                                    <textarea className='form-cntrl w-100'
                                        value={type === "online" ? rowData.about_me : formData.about_me}
                                        onChange={(e) => handleChange(e, 'about_me')}
                                        name="about_me"
                                        placeholder='About'
                                        labelname={"About"} />
                                </div>
                            )}

                        </Col>
                        {error && (
                            <Col lg='12' md='12' xs='12' className='py-2'>
                                <Alert variant='danger' >
                                    {error}
                                </Alert>
                            </Col>
                        )}
                    </Row>
                </Container>}
        </div >
    )
}

export default OnlineCreation