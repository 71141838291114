import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from './components/sidebar/SideBar'
import './components/sidebar/sidebar.css'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import DashBoard from './pages/DashBoard';
import User from './pages/User';
import UserCreation from './pages/UserCreation';
import Company from './pages/Company';
import OnlineRegister from './pages/OnlineRegister';
import OfflineRegister from './pages/OfflineRegister';
import Plans from './pages/Plans';
import OnlineKYC from './pages/OnlineKYC';
import OfflineCreation from './pages/OfflineCreation';
import PlansCreation from './pages/planscreation';
import OfflineReport from './pages/OfflineReport';
import OnlineCreation from './pages/OnlineCreation';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
    return <Navigate to="/dashboard" replace />;
  };

  const handleLogout = () => {
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
  };
  console.log(loggedIn);
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} ></Route>
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path='/dashboard' element={<DashBoard />}></Route>
            <Route path='/console/user' element={<User />}></Route>
            <Route path='/console/user/create' element={<UserCreation />}></Route>
            <Route path='/console/company' element={<Company />}></Route>
            <Route path='/console/onlineregister' element={<OnlineRegister />}></Route>
            <Route path='/console/onlineregister/create' element={<OnlineCreation />}></Route>
            <Route path='/console/offlineregister' element={<OfflineRegister />}></Route>
            <Route path='/console/offlineregister/create' element={<OfflineCreation />}></Route>
            <Route path='/console/plans' element={<Plans />}></Route>
            <Route path='/console/plans/create' element={<PlansCreation />}></Route>
            <Route path='/console/report/' element={<OfflineReport />}></Route>

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;