import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { ClickButton } from '../components/ClickButton';
import { useNavigate } from 'react-router-dom';
import { TextInputForm, DropDownUI } from '../components/Forms';
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { useLocation } from 'react-router-dom';
import PageNav from '../components/PageNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [showPassword, setShowPassword] = useState(false);
  const DropList = [
    {
      value: 'Admin',
      label: 'Admin'
    },
    {
      value: 'Super Admin',
      label: 'Super Admin'
    },
    {
      value: 'Staff',
      label: 'Staff'
    }
  ];
  const initialState = type === 'edit' ? { ...rowData } : {
    name: "",
    role: "",
    user_name: "",
    password: ""
  };
  const [formData, setFormData] = useState(initialState);
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value
    });
  };



  const handleSubmit = async () => {
    for (const key in formData) {
      if (formData[key] === '') {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; // Exit the function early if any field is empty
      }
    }
    try {
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const responseData = await response.json();


      if (responseData.head.code === 200) {
        toast.success('🦄 Successfullu!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/user");
        }, 1000)

      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdate = async () => {
    try {
      const updatedFormData = {
        edit_user_id: rowData.id,
        name: formData.name,
        role: formData.role,
        user_name: formData.user_name,
        password: formData.password
      };
      var bodydata = JSON.stringify(updatedFormData)

      

      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/auth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },

        body: bodydata
      });


      const responseData = await response.json();


      if (responseData.head.code === 200) {
        toast.success('User updated successfully!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Navigate to the user list page after a delay
        setTimeout(() => {
          navigate("/console/user");
        }, 2000);

      } else {
        console.log('Error:', responseData.head.msg);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col lg='12' className='py-3'>
          <PageNav pagetitle={`User ${type === 'view' ? 'View' : type === 'edit' ? 'Update' : 'Creation'}`} />
        </Col>
        <Col lg='3' md='6' xs='12' className='py-2'>
          {type === "edit" ? (
            <TextInputForm
              placeholder={'Name'}
              labelname={'Name'}
              name='name'
              value={formData.name}
              onChange={(e) => handleChange(e, 'name')}
            />
          ) : (
            <TextInputForm
              placeholder={'Name'}
              labelname={'Name'}
              name='name'
              value={type === "view" ? rowData.name : formData.name}
              onChange={(e) => handleChange(e, 'name')}
            />
          )}

        </Col>
        <Col lg='3' md='6' xs='12' className='py-2'>
          {type === "edit" ? (
            <DropDownUI
              optionlist={DropList}
              className="select"
              labelname={"Role"}
              placeholder={"Role"}
              name='role'
              value={formData.role}
              onChange={(updatedFormData) => setFormData({ ...formData, role: updatedFormData.role })}


            />
          ) : (
            <DropDownUI
              optionlist={DropList}
              className="select"
              labelname={"Role"}
              name='role'
              placeholder={"Role"}
              value={type === "view" ? rowData.role : formData.role}
              onChange={(updatedFormData) => setFormData({ ...formData, role: updatedFormData.role })}

            />
          )}
        </Col>
        <Col lg='3' md='6' xs='12' className='py-2'>
          {type === "edit" ? (
            <TextInputForm
              placeholder={'User Name'}
              labelname={'User Name'}
              name='user_name'
              value={formData.user_name}
              onChange={(e) => handleChange(e, 'user_name')}
            />
          ) : (
            <TextInputForm
              placeholder={'User Name'}
              labelname={'User Name'}
              name='user_name'
              value={type === "view" ? rowData.user_name : formData.user_name}
              onChange={(e) => handleChange(e, 'user_name')}
            />
          )}

        </Col>
        <Col lg='3' md='6' xs='12' className='py-2'>
          {type === "view" ? null : (
            <TextInputForm
              placeholder={'Password'}
              suffix_icon={showPassword ? <VscEye onClick={() => setShowPassword(false)} /> : <VscEyeClosed onClick={() => setShowPassword(true)} />}
              labelname={'Password'}
              type={showPassword ? 'text' : 'password'}
              name='password'
              value={formData.password}
              onChange={(e) => handleChange(e, 'password')}
            />
          )}

        </Col>
        <Col lg='12' md='12' xs='12'>
          <div className='page-nav text-center py-3'>
            {type === "view" ? (
              <span className='mx-2'>
                <ClickButton label={<>back</>} onClick={() => navigate("/console/user")} />
              </span>
            ) : (
              <>
                {type === "edit" ? (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <span className='mx-2'>
                      <ClickButton label={<>Update</>} onClick={handleUpdate} />
                    </span>
                  </>
                ) : (
                  <>
                    <ToastContainer
                      position="top-center"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="colored"
                    />
                    <span className='mx-2'>
                      <ClickButton label={<>Submit</>} onClick={handleSubmit} />
                    </span>
                  </>
                )}
                <span className='mx-2'>
                  <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")} />
                </span>
              </>
            )}

          </div>
        </Col>
        {/* {showAlert && (
          <Col lg='12' md='12' xs='12' className='py-2'>
            <Alert variant='danger' onClose={() => setShowAlert(false)} dismissible>
              Please provide all details.
            </Alert>
          </Col>
        )} */}
      </Row>
    </Container>
  );
};

export default UserCreation;
