import React ,{useEffect,useState}from 'react'
import { Container, Row , Col } from 'react-bootstrap'
import { ClickButton } from '../components/ClickButton'
import {  DropDownUI } from '../components/Forms'
import TableUI from '../components/Table';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
const logoImage = require('../components/sidebar/images/logo.png');
const OfflineReportHead = ["No", "Mode","Status","Gender","Name","Mobile Number","Email","Plan"]

  const DropListmode = [
    {
      value: 'online',
      label: 'online'
    },
    {
      value: 'offline',
      label: 'offline'
    }
  ];
  const DropListgender = [
    {
      value: 'Male',
      label: 'Male'
    },
    {
      value: 'Female',
      label: 'Female'
    }
  ];
  const DropListverification = [
    {
      value: 'Verified',
      label: 'Verified'
    },
    {
      value: 'Unverified',
      label: 'Unverified'
    }
  ];
const OfflineReport = () => {
    const [userData, setUserData] = useState([]);
    const [planData, setplanData] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const initialState ={
        mode: "",
        gender: "",
        status: "",
        plan: ""
      };
      const [formData, setFormData] = useState(initialState);
    
    const fetchplanData = async () => {
        try {
          setLoading(true)
          const response = await fetch('https://admin.orchidsmatrimony.com/api/src/plan.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              search_text: ''
            })
          });
          setLoading(false)
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
    
          const responseData = await response.json();
    
          if (responseData.head.code === 200) {
            setplanData(responseData.body.plans);
          } else {
            throw new Error(responseData.head.msg);
          }
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
      };
    useEffect(() => {
        const fetchData = async () => {
          try {
            
            const response = await fetch('https://admin.orchidsmatrimony.com/api/src/online_register.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                search_text: ''
              })
            });
           
            if (!response.ok) {
              throw new Error('Failed to fetch data');
            }
      
            const responseData = await response.json();
      
            if (responseData.head.code === 200) {
                let sortedData = responseData.body.matrimony_profiles;


                if (formData.mode) {
                    sortedData = sortedData.filter(plan => plan.type_of_register === formData.mode);
                }
                if (formData.gender) {
                    sortedData = sortedData.filter(plan => plan.gender === formData.gender);
                }
                if (formData.status) {
                    if(formData.status === 'Verified'){
                        sortedData = sortedData.filter(plan => plan.id_verfication === 'yes');
                    }else{
                        sortedData = sortedData.filter(plan => plan.id_verfication === 'no');
                    }
                   
                }
                if(formData.plan){
                    if(formData.plan === 'Free'){
                        sortedData = sortedData.filter(plan => plan.membership_tag === null);
                    }else{
                        sortedData = sortedData.filter(plan => plan.membership_tag  === formData.plan);
                    }
                }

              setUserData(sortedData);
            } else {
              throw new Error(responseData.head.msg);
            }
          } catch (error) {
            console.error('Error fetching data:', error.message);
          }
        };
        fetchplanData();
        fetchData(); // Call fetchData directly in useEffect
      
      }, [formData]);
      const handleLoad = () => {
        window.location.reload();
    }
    const optionList = [
        ...planData.map(plan => ({
            value: plan.plan_name,
            label: plan.plan_name
        })),
        {
            value: "Free",
            label: "Free"
        }
    ];
    function generatePDF() {
      const input = document.getElementById('table-container');
      html2canvas(input, {
          width: 1300,
          height: 850,
          scale: 1, // Decrease scale to reduce image size
          logging: true, // Enable logging for debugging
          useCORS: true, // Enable cross-origin resource sharing
          imageTimeout: 15000, // Timeout for loading images
          allowTaint: true // Allow taint
      })
      .then((canvas) => {
          const pdf = new jsPDF('l', 'in', 'letter');
  
          // Load company logo asynchronously
          const img = new Image();
          img.onload = function() {
              // Add company logo at the top center
              const imgWidth = 2; // Width of the logo in inches
              const imgHeight = imgWidth / this.width * this.height; // Maintain aspect ratio
              const logoX = (pdf.internal.pageSize.width - imgWidth) / 2; // Center horizontally
              const logoY = 0.1; // Top margin in inches
              pdf.addImage(img, 'PNG', logoX, logoY, imgWidth, imgHeight);
  
              // Add report title below center
              let title = 'Orchids Report'; // Default title
  
              // Modify title based on formData
              if (formData.mode || formData.gender || formData.status || formData.plan) {
                  title = 'Orchids Report'; // You can customize this based on your actual data
                  const extraTitles = []; // Array to store additional titles
  
                  if (formData.mode) extraTitles.push(`Mode: ${formData.mode}`);
                  if (formData.gender) extraTitles.push(`Gender: ${formData.gender}`);
                  if (formData.status) extraTitles.push(`Status: ${formData.status}`);
                  if (formData.plan) extraTitles.push(`Plan: ${formData.plan}`);
  
                  // Check if there are extra titles to add
                  if (extraTitles.length > 0) {
                      title += '\n(' + extraTitles.join(', ') + ')'; // Add extra titles inside brackets separated by commas
                  }
              }
  
              const titleX = pdf.internal.pageSize.width / 2; // Center horizontally
              const titleY = 1.5; // Below logo in inches
              pdf.setFontSize(16);
              pdf.setFont('helvetica', 'bold'); // Set font style to bold
              pdf.text(titleX, titleY, title, null, null, 'center');
  
              // Add image to PDF with fixed dimensions
              pdf.addImage(canvas.toDataURL('image/jpeg', 0.8), 'JPEG', 0.5, 2, 11, 8); // Decrease JPEG quality to reduce file size
  
              // Save PDF
              pdf.save('table.pdf');
          };
  
          img.onerror = function() {
              console.error('Failed to load the company logo.');
          };
  
          img.src = logoImage; // Set the image source
      })
      .catch((error) => {
          console.error('Error generating PDF:', error);
      });
  }
  return (
    <div>
        <div className=''>
            <Container>
                <Row>
                    <Col lg='6' md="6" xs="6" className='align-self-center py-3'>
                        <div> Report</div>
                    </Col>
                    <Col lg='6' md="6" xs="6" className='align-self-center py-3 text-end'>
                      <span>
                        <span className='mx-2'><ClickButton label={<>PDF</>} onClick={generatePDF}></ClickButton></span>
                        <span className='mx-2'><ClickButton label={<>Undo Filter</>} onClick={handleLoad}></ClickButton></span>
                      </span>
                    </Col>
                     <Col lg='12' md='12' xs='6' className='py-1'>
                     
                    </Col>
                    <Col lg="3" md="4" xs="12" className='py-3'>
                        <DropDownUI 
                        labelname={<>Mode</>}
                        optionlist={DropListmode}
                        className="select"
                        name ="mode"
                        value={formData.mode}
                        onChange={(updatedFormData) => setFormData({ ...formData, mode: updatedFormData.mode })}
                        ></DropDownUI>
                    </Col>
                    <Col lg="3" md="4" xs="12" className='py-3'>
                        <DropDownUI
                         labelname={<>verification</>}
                         optionlist={DropListverification}
                         className ="select"
                         name= "status"
                         value={formData.status}
                         onChange={(updatedFormData) => setFormData({ ...formData, status: updatedFormData.status })}
                        ></DropDownUI>
                    </Col>
                    <Col lg="3" md="4" xs="12" className='py-3'>
                        <DropDownUI 
                        labelname={<>Gender</>}
                        optionlist={DropListgender}
                        className ="select"
                        name= "gender"
                        value={formData.gender}
                        onChange={(updatedFormData) => setFormData({ ...formData, gender: updatedFormData.gender })}
                        ></DropDownUI>
                    </Col>
                    <Col lg="3" md="4" xs="12" className='py-3'>
                        <DropDownUI 
                        labelname={<>Plan</>}
                        optionlist={optionList}
                        className ="select"
                        name= "plan"
                        value={formData.plan}
                        onChange={(updatedFormData) => setFormData({ ...formData, plan: updatedFormData.plan })}
                        ></DropDownUI>
                    </Col>
                    <Col lg='12'>
                        <div className='py-4' id="table-container">
                            <TableUI headers={OfflineReportHead} body={userData} type="Report" style={{ 'borderRadius': '5px' }} />
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
  )
}

export default OfflineReport