import React, { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from '../components/Table';
import Pagination from '../components/Pagnation';
import { ClickButton } from '../components/ClickButton';
import { TextInputForm } from '../components/Forms';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
const OfflineRegisterTablehead = ["No", "Bride / Groom Name", " Mobile Number", "Location", "Action",]
const OfflineRegister = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [extraData, setextraUserData] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;




  // Set the merged data as userEndData
  const [userEndData, setUserEndData] = useState([]);



  useEffect(() => {
    fetchData();
  }, []);

  
  
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/offline_register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          search_text: ''
        })
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setUserData(responseData.body.matrimony_profiles);
        setextraUserData(responseData.body.users);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    const mergedData = userData.map(user => {
      const extraUserData = extraData.find(extraUser => extraUser.email === user.email);
      return {
        ...user,
        ...extraUserData
      };
    });
    setUserEndData(mergedData);
    
  }, [userData, extraData]);


  return (
    <>
      <Container fluid>
        <Row>
          <Col lg='7' md='4' xs='6'>
            <div className='page-nav py-3'>
              <span class="nav-list">Offline Register</span>
            </div>
          </Col>
          <Col lg='5' md='3' xs='6' className='align-self-center text-end'>
            <ClickButton label={<>Add New</>} className='create-btn' onClick={() => navigate("create")}></ClickButton>
          </Col>
          <Col lg='3' md='5' xs='12' className='py-1'>
            <TextInputForm placeholder={"Name, Mobile No."} prefix_icon={<FaMagnifyingGlass />} labelname={"Search Name"}> </TextInputForm>
          </Col>
          <Col lg={9} md={12} xs={12} className='py-2'>
            <Pagination totalItems={userData.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
          </Col>
          <Col lg='12' md='12' xs='12' className='px-0'>
            <div className='py-1'>
              {/* <MobileView sno={"01"} name={"Murugesan"} subname={"9994307256"} dataflow={"Nilagiri"}></MobileView> */}
              {loading ? <center><Spinner animation="border" variant="dark" /> </center> :
                <TableUI headers={OfflineRegisterTablehead} body={userEndData} type="OFFOR" style={{ 'borderRadius': '5px' }} />}
            </div>
          </Col>
          <Col lg={12} md={12} xs={12}>
            <Pagination totalItems={userData.length} itemsPerPage={itemsPerPage} onPageChange={handlePageChange} />
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default OfflineRegister