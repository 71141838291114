import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ClickButton } from '../components/ClickButton';
import { useNavigate } from 'react-router-dom';
import { Calender, TextInputForm } from '../components/Forms';
import PageNav from '../components/PageNav';
import { differenceInCalendarMonths } from 'date-fns';
import {useLocation} from  "react-router";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PlansCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  
  const initialState = type === 'edit' ? { ...rowData } :{
    plan_name: '',
    plan_price: '',
    topup_amount : '',
    des: '',
    duration : '',
    topup_count : ''
  };
  const [planData, setPlanData] = useState(initialState);

  console.log(planData);

  
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
 
  
    setPlanData({
      ...planData,
      [fieldName]: value
    });
  };

  const handleSubmit = async () => {
    try {
      for (const key in planData) {
        if (planData[key] === '') {
          toast.error(`${key} cannot be empty!`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return; // Exit the function early if any field is empty
        }
      }
        const requestBody = {
            plan_name: planData.plan_name,
            plan_price: planData.plan_price,
            duration: planData.duration, // Assuming monthsDifference is the duration
            description: planData.des,
            topup_amount : planData.topup_amount,
            topup_count : planData.topup_count
          };
         
        const response = await fetch('https://admin.orchidsmatrimony.com/api/src/plan.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });
  
        const responseData = await response.json();
  
  
        if (responseData.head.code === 200) {
          toast.success('🦄 Plans Data Successfull!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/console/plans");
          }, 1000)
        
        } else {
            console.log('Error:', responseData.head.msg);
        }
      } catch (error) {
        console.error('Error:', error);
      }
  };
  const handleUpdate = async () =>{
    try {
    const requestBody = {
      edit_plan_id :rowData.id,
      plan_name:  planData.plan_name,
      plan_price:  planData.plan_price,
      duration:  planData.duration, // Assuming monthsDifference is the duration
      description: planData.des,
      topup_amount : planData.topup_amount,
      topup_count : planData.topup_count
    };
 
    const response = await fetch('https://admin.orchidsmatrimony.com/api/src/plan.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });
  
        const responseData = await response.json();
  

  
        if (responseData.head.code === 200) {
          toast.success('Plans updated successfully!', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
  
          // Navigate to the user list page after a delay
          setTimeout(() => {
            navigate("/console/plans");
          }, 2000);
         
        } else {
            console.log('Error:', responseData.head.msg);
        }
      } catch (error) {
        console.error('Error:', error);
      }
  };

  const setLabel = (date, label) => {
    handleChange(label, date);

    if (label === 'fromDate' && planData.toDate) {
      // Calculate the difference in months between fromDate and toDate
      const monthsDifference = differenceInCalendarMonths(planData.toDate, date);
      setPlanData(prevData => ({
        ...prevData,
        monthsDifference: monthsDifference
      }));
    } else if (label === 'toDate' && planData.fromDate) {
      // Calculate the difference in months between toDate and fromDate
      const monthsDifference = differenceInCalendarMonths(date, planData.fromDate);
      setPlanData(prevData => ({
        ...prevData,
        monthsDifference: monthsDifference
      }));
    }
  };

  return (
    <Container>
      <Row className=''>
        <Col lg='12' className='py-3'>
          <PageNav pagetitle={type ==="view" ? "Plan" : type ==="edit" ? "Plan Edit" : "Add New Plan" }/>
        </Col>
        <Col lg="3" md='3' xs="12" className='py-3'>
        {type === "edit" ? (
          <TextInputForm
            placeholder="Enter Plan Name"
            labelname="Enter Plan Name"
            name={"plan_name"}
            value={planData.plan_name}
            onChange={(e) => handleChange(e,'plan_name')}
          />
          ) : (
            <TextInputForm
            placeholder="Enter Plan Name"
            labelname="Enter Plan Name"
            name={"plan_name"}
            value={type === "view" ? rowData.plan_name : planData.plan_name}
            onChange={(e) => handleChange(e,'plan_name')}
          />
            )}
        </Col>
        <Col lg="3" md='3' xs="12" className='py-3'>
        {type === "edit" ? (
          <TextInputForm
            placeholder="Plan Price"
            labelname="Plan Price"
            name={"plan_price"}
            value={planData.plan_price}
            onChange={(e) => handleChange(e,'plan_price')}
          />
          ) : (
            <TextInputForm
            placeholder="Plan Price"
            labelname="Plan Price"
            name={"plan_price"}
            value={type === "view" ? rowData.plan_price : planData.plan_price}
            onChange={(e) => handleChange(e,'plan_price')}
          />
            )}
        </Col>
        {type ==="edit" ? (
          <>
          <Col lg="3" md='3' xs="12" className='py-3'>
          <TextInputForm
          placeholder="duration"
          labelname="duration"
          name={"duration"}
          
          onChange={(e) => handleChange(e,'duration')}
          value={planData.duration}/>
          </Col>
        </>
        ) : (
          <>
           <Col lg="3" md='3' xs="12" className='py-3'>
          <TextInputForm
          placeholder="duration"
          labelname="duration"
          name={"duration"}
          
          onChange={(e) => handleChange(e,'duration')}
          value={type === "view" ? rowData.duration : type === "edit" ? rowData.duration: planData.duration}/>
          </Col>
          </>
        )}
          <Col lg="3" md='3' xs="12" className='py-3'>
        {type === "edit" ? (
          <TextInputForm
            placeholder="Plan User Count"
            labelname="Plan User Count"
            name={"des"}
            value={planData.des}
            onChange={(e) => handleChange(e,'des')}
          />
          ) : (
            <TextInputForm
            placeholder="Plan User Count"
            labelname="Plan User Count"
            name={"des"}
            value={type === "view" ? rowData.des : planData.des}
            onChange={(e) => handleChange(e,'des')}
          />
            )}
        </Col>
          <Col lg="3" md='3' xs="12" className='py-3'>
        {type === "edit" ? (
          <TextInputForm
            placeholder="Plan Topup Amount"
            labelname="Plan Topup Amount"
            name={"topup_amount"}
            value={planData.topup_amount}
            onChange={(e) => handleChange(e,'topup_amount')}
          />
          ) : (
            <TextInputForm
            placeholder="Plan Topup Amount"
            labelname="Plan Topup Amount"
            name={"topup_amount"}
            value={type === "view" ? rowData.topup_amount : planData.topup_amount}
            onChange={(e) => handleChange(e,'topup_amount')}
          />
            )}
        </Col>

        <Col lg="3" md='3' xs="12" className='py-3'>
        {type === "edit" ? (
          <TextInputForm
            placeholder="Plan Topup User Count"
            labelname="Plan Topup User Count"
            name={"topup_count"}
            value={planData.topup_count}
            onChange={(e) => handleChange(e,'topup_count')}
          />
          ) : (
            <TextInputForm
            placeholder="Plan Topup User Count"
            labelname="Plan Topup User Count"
            name={"topup_count"}
            value={type === "view" ? rowData.topup_count : planData.topup_count}
            onChange={(e) => handleChange(e,'topup_count')}
          />
            )}
        </Col>
          {/* <Col lg='3' md='3' xs="12" className='py-3'>
          <div className='pb-2 px-3'>
            
          </div>
          <Calender setLabel={(date) => setLabel(date, 'fromDate')} calenderlabel="From Date" />
        </Col>
        <Col lg='3' md='3' xs="12" className='py-3'>
          <div className='pb-2 px-3'>
           
          </div>
          <Calender setLabel={(date) => setLabel(date, 'toDate')} calenderlabel="To Date" />
        </Col> */}
         
        
        {/* <Col lg='12' md='12' xs='12' className='py-3'>
        {type === "edit" ? (
          <>
          <div className='pb-2 px-3'>
            <label>Terms And Conditions</label>
          </div>
          <textarea
              className='form-control w-100'
              placeholder='Terms And Conditions'
              labelname='Terms And Conditions'
              cols='20'
              rows='10'
              value={planData.des}
              onChange={(e) => handleChange(e,'des')}
            />
            </>
            ) : (
              <>
              <div className='pb-2 px-3'>
            <label>Terms And Conditions</label>
          </div>
          <textarea
              className='form-control w-100'
              placeholder='Terms And Conditions'
              labelname='Terms And Conditions'
              cols='20'
              rows='10'
              value={type === 'view' ? rowData.des : type === "edit" ? rowData.des : planData.des}
              onChange={(e) => handleChange(e,'des')}
              />
                </>
              )} 
        </Col> */}
        <Col lg='12' md='12' xs='12'>
          <div className='page-nav text-center py-3'>
          {type === 'view' ? (
            <>
              <span className='mx-2'><ClickButton label={'back'} onClick={() => navigate("/console/plans")} /></span>
            </>
          ) : (
            <>
              {type === 'edit' ? (

                 <>
                  <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                <span className='mx-2'><ClickButton label={'Update'} onClick={handleUpdate} /></span>
                <span className='mx-2'><ClickButton label={'Cancel'} onClick={() => navigate("/console/plans")} /></span>
                </>
              ) : (
                <>
                 <ToastContainer
                  position="top-center"
                  autoClose={2000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                  <span className='mx-2'><ClickButton label={'Submit'} onClick={handleSubmit} /></span>
                  <span className='mx-2'><ClickButton label={'Cancel'} onClick={() => navigate("/console/plans")} /></span>
                </>
              )}
            </>
          )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PlansCreation;
