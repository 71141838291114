import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { MdWoman } from "react-icons/md";
import { MdOutlineMan, MdOutlineStorefront } from "react-icons/md";
import { MdLanguage } from "react-icons/md";
import Spinner from 'react-bootstrap/Spinner';
const DashBoard = ({isloggIn}) => {
  const [userData, setUserData] = useState([null]);
  const [loading, setLoading] = useState(false);
  DashBoard.defaultProps = {
    isloggIn: false 
  };
 
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await fetch('https://admin.orchidsmatrimony.com/api/src/dashboard.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          count: ''
        })
      });
      setLoading(false)
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setUserData(responseData.body);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  return (
    <>
     {loading ? <center><Spinner animation="border" variant="dark" /> </center> :
      <Container>
      <Row>
        <Col lg='6' md='6' xs='12' className='py-3'>
          <div className='counter-card cyan'>
            <span className=''><MdWoman /></span>
            <span className="count-numbers plus bold">{userData?.bride_count || 0}</span>
            <span className="count-name regular">Total Bride</span>
          </div>
        </Col>
        <Col lg='6' md='6' xs='12' className='py-3'>
          <div className='counter-card blue'>
            <span className=''><MdOutlineMan /></span>
            <span className="count-numbers plus bold">{userData?.groom_count || 0}</span>
            <span className="count-name regular">Total Groom</span>
          </div>
        </Col>
        <Col lg='6' md='6' xs='12' className='py-3'>
          <div className='counter-card red'>
            <span><MdLanguage /></span>
            <span className="count-numbers plus bold">{userData?.online_count || 0}</span>
            <span className="count-name regular">Online Register</span>
          </div>
        </Col>
        <Col lg='6' md='6' xs='12' className='py-3'>
          <div className='counter-card green'>
            <span className=''><MdOutlineStorefront /></span>
            <span className="count-numbers plus bold">{userData?.offline_count || 0}</span>
            <span className="count-name regular">Offline Register</span>
          </div>
        </Col>
      </Row>
    </Container>}
    </>
   
  )
}

export default DashBoard