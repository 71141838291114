import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import TableUI from '../components/Table';
import MobileView from '../components/MobileView';
const UserTablehead = ["No", "Company Name", " Place", "Action",]
const UserData = [
  { values: ["1", "Orchids Matrimony", "Palladam",] },
]
const Company = () => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg='4' md='4' xs='6'>
            <div className='page-nav py-3'>
              <span class="nav-list"> Company Details</span>
            </div>
          </Col>
          <Col lg='12' md='12' xs='12'>
            <div className='py-4'>
              <MobileView sno={"01"} name={"Orchids Matrimony"} subname={"Virudhunagar"}/>
              <TableUI headers={UserTablehead} body={UserData} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Company