// import React from 'react'
// import {Button} from 'react-bootstrap'
// import { MdChevronRight,MdChevronLeft } from "react-icons/md";
// import '../App.css'
// const Pagnation = () => {
//   return (
//     <div>
//         <div className='navbar'>
//             <div className='ms-auto '>
//               <span className='role '> Viewing <span className='fw-bolder' >1-10</span> of <span className='fw-bolder'> 26</span></span>
//                 <Button href="#home" className='page-nav-link'><MdChevronLeft /></Button>
//                 <Button href="#home"  className='page-nav-link'><MdChevronRight /></Button>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Pagnation
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  return (
    <div className='navbar'>
      <div className='ms-auto '>
        <span className='role'>
          Viewing{' '}
          <span className='fw-bolder'>
            {((currentPage - 1) * itemsPerPage) + 1}-{Math.min(currentPage * itemsPerPage, totalItems)}
          </span>{' '}
          of <span className='fw-bolder'>{totalItems}</span>
        </span>
        <Button href='#home' className='page-nav-link' onClick={handlePrevPage}>
          <MdChevronLeft />
        </Button>
        <Button href='#home' className='page-nav-link' onClick={handleNextPage}>
          <MdChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
